import { toast } from 'react-toastify'

// const SERVERLESS_FN_URL = '/api/submit'
const SERVERLESS_FN_URL =
  'https://2aes-group-website-airtable.azaz.workers.dev/submit'
// const SERVERLESS_FN_URL = 'http://localhost:8787/submit'

const handleSubmit = async event => {
  event.preventDefault()

  const formData = new FormData(event.target)

  toast.promise(
    fetch(SERVERLESS_FN_URL, {
      method: 'POST',
      body: formData
    }),
    {
      pending: 'Invio in corso...',
      success: 'Invata! 👌',
      error: 'Errore!! 🤯'
    }
  )

  // let options = {
  //   // headers: {
  //   //   'Content-Type': 'multipart/form-data',
  //   //   'Access-Control-Allow-Origin': '*'
  //   // },
  //   method: 'POST',
  //   body: formData
  // }

  // fetch(SERVERLESS_FN_URL, options)
  //   .then(response => toast.success(response))
  //   .catch(error => toast.error(error))
}

export default function ContactForm() {
  return (
    <section
      className="py-10 relative px-6 bg-brand  text-whiteBrand"
      id="contactform"
    >
      <h1 className="font-raleway font-normal text-4xl text-center sm:text-5xl md:text-6xl font-heading text-whiteBrand pb-5">
        Contattaci
      </h1>
      <p className="text-center">
        Se lo desidera può chiederci, gratuitamente, informazioni sui nostri
        servizi e le risponderemo il prima possibile.
      </p>
      <form
        // action={SERVERLESS_FN_URL}
        // method="POST"
        onSubmit={handleSubmit}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 max-w-xl mx-auto mb-12"
      >
        <div className="">
          <label
            htmlFor="first_name"
            className="block text-sm font-medium text-whiteBrand"
          >
            Nome
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first_name"
              id="first_name"
              autoComplete="given-name"
              placeholder=""
              required
              className="py-3 px-4 block w-full shadow-sm text-brand rounded-md"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="last_name"
            className="block text-sm font-medium text-whiteBrand"
          >
            Cognome
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last_name"
              id="last_name"
              autoComplete="family-name"
              placeholder=""
              required
              className="py-3 px-4 block w-full shadow-sm text-brand rounded-md"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-whiteBrand"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder=""
              required
              className="py-3 px-4 block w-full shadow-sm text-brand  rounded-md"
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-whiteBrand"
            >
              Telefono
            </label>
            <span id="phone-optional" className="text-sm text-whiteBrand">
              (opzionale)
            </span>
          </div>
          <div className="mt-1">
            <input
              type="text"
              name="phone"
              id="phone"
              autoComplete="tel"
              className="py-3 px-4 block w-full shadow-sm text-brand  rounded-md"
              placeholder=""
              aria-describedby="phone-optional"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-whiteBrand"
          >
            Oggetto
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder=""
              required
              className="py-3 px-4 block w-full shadow-sm text-brand  rounded-md"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-whiteBrand"
            >
              Messaggio
            </label>
            <span id="message-max" className="text-sm">
              Massimo 500 caratteri
            </span>
          </div>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              maxLength={500}
              className="py-3 px-4 block w-full shadow-sm text-brand rounded-md"
              aria-describedby="message-max"
              placeholder=""
              required
              defaultValue=""
            />
          </div>
        </div>

        <div className="sm:col-span-2 sm:flex sm:justify-between">
          <div>
            <label className="flex items-center mt-4 space-x-3">
              <input
                required
                type="checkbox"
                className="h-4 w-4 border border-brand-hot rounded-sm bg-white bg-center cursor-pointer
              checked:bg-brand-hot checked:border-brand-hot
              focus:outline-none
              transition duration-200 "
              />
              <span className="cursor-pointer">
                Ho letto e accetto la{' '}
                <a
                  href="/privacy-policy"
                  className="border-b-2 border-whiteBrand"
                >
                  privacy policy
                </a>
              </span>
            </label>
          </div>
          <button
            type="submit"
            className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm
            text-base font-medium bg-whiteBrand text-brand hover:bg-brand-hot sm:w-auto
            umami--click--contactform-send"
          >
            Invia
          </button>
        </div>
      </form>
    </section>
  )
}
