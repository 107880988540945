/* eslint-disable no-unused-vars */
export default function LogoPosgres({ ...props }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1454 470"
      {...props}
    >
      <g id="Layer_3" data-name="Layer 3">
        <path
          d="M335.18,336.54c2.83-23.62,2-27.08,19.56-23.24l4.47.39c13.51.61,31.2-2.18,41.58-7,22.37-10.38,35.63-27.7,13.57-23.15-50.29,10.38-53.75-6.65-53.75-6.65,53.11-78.81,75.31-178.84,56.15-203.33C364.49,6.77,274,38.36,272.5,39.18l-.49.09a177.53,177.53,0,0,0-33.55-3.5c-22.76-.37-40,6-53.13,15.9,0,0-161.41-66.49-153.9,83.63C33,167.23,77.21,377,129.9,313.6c19.26-23.16,37.87-42.74,37.87-42.74A48.83,48.83,0,0,0,199.68,279l.9-.76a34.77,34.77,0,0,0,.36,9c-13.61,15.19-9.61,17.86-36.72,23.44-27.46,5.66-11.33,15.74-.8,18.37,12.77,3.19,42.3,7.71,62.27-20.23l-.8,3.19c5.33,4.26,5,30.62,5.72,49.45s2,36.41,5.86,46.78,8.37,37,44,29.4c29.81-6.39,52.6-15.58,54.68-101.1"
          stroke="#000"
          strokeWidth="37.4"
        />
        <path
          d="M414.33,283.54c-50.3,10.36-53.76-6.67-53.76-6.67C413.68,198.07,435.88,98,416.72,73.55,364.45,6.76,274,38.35,272.46,39.17l-.49.08a180,180,0,0,0-33.55-3.49c-22.77-.37-40,6-53.13,15.9,0,0-161.41-66.49-153.91,83.63C33,167.23,77.16,377,129.86,313.6c19.25-23.16,37.86-42.74,37.86-42.74A48.83,48.83,0,0,0,199.67,279l.9-.76a35.68,35.68,0,0,0,.36,9c-13.6,15.19-9.6,17.86-36.72,23.44-27.46,5.66-11.33,15.74-.79,18.37,12.76,3.19,42.3,7.71,62.26-20.23l-.79,3.19c5.31,4.26,9.05,27.71,8.42,49s-1,35.86,3.15,47.26,8.37,37.05,44,29.4c29.81-6.39,45.26-22.94,47.41-50.55,1.52-19.63,5-16.73,5.19-34.28l2.77-8.31c3.19-26.61.51-35.2,18.87-31.2l4.46.39c13.52.61,31.21-2.17,41.6-7,22.35-10.38,35.61-27.7,13.57-23.14Z"
          fill="#336791"
        />
        <path
          d="M227.84,298.79c-1.38,49.51.35,99.38,5.19,111.5s15.23,35.69,50.9,28c29.81-6.4,40.66-18.75,45.36-46.05,3.47-20.08,10.15-75.85,11-87.28"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
        <path
          d="M185.08,50.55s-161.52-66-154,84.11C32.66,166.6,76.85,376.33,129.54,313c19.26-23.17,36.67-41.34,36.67-41.34"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
        <path
          d="M272.33,38.51c-5.59,1.75,89.85-34.89,144.09,34.41,19.16,24.49-3,124.52-56.15,203.34"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
        <path
          d="M360.26,276.26s3.46,17,53.76,6.64c22-4.54,8.78,12.78-13.58,23.17C382.1,314.58,341,316.77,340.3,305c-1.73-30.35,21.64-21.13,20-28.73-1.53-6.86-12-13.58-18.9-30.34-6-14.63-82.79-126.85,21.29-110.18,3.81-.79-27.15-99-124.55-100.6s-94.23,119.75-94.23,119.75"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="12.47"
        />
        <path
          d="M200.58,286.64C187,301.81,191,304.46,163.86,310.06c-27.46,5.66-11.33,15.73-.8,18.36,12.77,3.2,42.31,7.72,62.27-20.22,6.07-8.51,0-22.09-8.39-25.55C212.91,281,207.51,278.87,200.58,286.64Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
        <path
          d="M199.69,286.37c-1.37-8.92,2.93-19.53,7.54-31.94,6.92-18.63,22.89-37.25,10.11-96.34-9.52-44-73.39-9.16-73.43-3.19s2.89,30.25-1.07,58.55c-5.16,36.91,23.49,68.12,56.49,64.94"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
        <path
          d="M184.49,154c-.29,2,3.74,7.48,9,8.21s9.71-3.52,10-5.55-3.74-4.29-9-5-9.72.33-10,2.36Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="4.16"
        />
        <path
          d="M343.92,149.84c.28,2-3.74,7.48-9,8.22s-9.72-3.52-10-5.56,3.74-4.29,9-5,9.71.34,10,2.37Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="2.08"
        />
        <path
          d="M362.65,135.73c.86,16-3.44,26.89-4,43.92-.8,24.75,11.8,53.08-7.19,81.44"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12.47"
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path d="M565.05,175q0,18-10.68,28.25t-29.28,10.22H475.65l.12,56.64-13.44.12V136.77l66.48-.24q17.16,0,26.7,10.22T565.05,175Zm-13.56.36q0-28.12-26.84-28.13H475.77v55.55h46.37Q551.48,202.77,551.49,175.35Z" />
        <path d="M665,226.42q0,21.36-11.16,33.35-12.48,13.46-36.36,13.45A56.59,56.59,0,0,1,597.63,270a38.46,38.46,0,0,1-14.82-10q-11.52-12.82-11.52-33.59t11.16-33.12q12.36-13.68,35-13.69,24,0,36.24,13.32Q665,204.95,665,226.42Zm-11.64,0q0-37.32-35.88-37.32-16.2,0-25.44,10a32.32,32.32,0,0,0-6.78,11.71,48.63,48.63,0,0,0-2.34,15.66q0,17.16,8.94,27.3t25.62,10.13q18.48,0,27.48-10.43A28.87,28.87,0,0,0,651.33,242,53.72,53.72,0,0,0,653.37,226.42Z" />
        <path d="M754.41,246q0,27-40.44,27a95.82,95.82,0,0,1-20.82-1.92,32.6,32.6,0,0,1-13.74-6.24,22.33,22.33,0,0,1-6.48-8.58A41.57,41.57,0,0,1,669.81,243l12.48-.12q0,10.58,6.6,15.61,7.08,6,25.08,6,28.32,0,28.32-16.9,0-9.83-9.48-13.54-1.92-.84-22.86-3.9t-26.1-5.45q-11.16-5.26-11.16-19,0-13.43,10-20a42.47,42.47,0,0,1,12.3-4.26,87.35,87.35,0,0,1,17.1-1.5q38.88,0,38.88,29.16H739.05q0-20.5-27.36-20.52-13.92,0-20.76,4.14t-6.84,12.76q0,8,9.24,11.5,5.4,1.92,24.84,4.32,17.4,1.92,23.88,5.27Q754.41,232.24,754.41,246Z" />
        <path d="M806.73,270.46c-1.92.39-3.63.67-5.1.84s-2.55.24-3.18.24q-12.24,0-17.64-4.27t-5.4-15v-60.7H760.53v-8.88h14.76l.12-28.92h11.4v28.92h19.92v9.12l-19.92-.24v59c0,4.41,1,7.46,2.94,9.14s5.46,2.53,10.5,2.53a8.34,8.34,0,0,0,2.4-.12c.87-.16,2.2-.44,4-.84Z" />
        <path d="M907.28,267.46q0,40-44.76,40-23.4,0-33.24-7.72-8.52-6.61-10.44-21.32l11.76-.12q2,10.74,7.44,15,7.2,5.67,24.12,5.67,18,0,25.86-8.18t7.86-23.25V251.85q-10.82,16.93-36.65,16.92-21.51,0-33.29-11.74a38.09,38.09,0,0,1-8.23-14,57.57,57.57,0,0,1-2.83-18.76q0-21.09,11.16-32.23,11.76-12.22,34.08-12.23,15.24,0,24.84,6.32,3.24,2.39,10.8,11.56l.12-15.12h11.4Zm-11.4-43.62q0-15.71-8.7-25.1t-25.5-9.4q-16.68,0-25.86,9.28t-9.18,25.69q0,35.35,35.88,35.35a37,37,0,0,0,13.8-2.4A31.52,31.52,0,0,0,887,250.19Q895.88,241,895.88,223.84Z" />
        <path d="M964.4,191.49a53.28,53.28,0,0,0-9.57-1q-28.11,0-28.11,37v42.73h-11.4l-.12-87.49h11.52v15.73h.6q2.86-9.3,9.09-14,6.83-4.58,20.34-4.59h7.65Z" />
        <path d="M1065.56,228.81H984.08q0,15,8.28,24.4,9.6,11,27.48,11,25.32,0,31.68-22.56h12.36a38.63,38.63,0,0,1-13.08,21.75,43.67,43.67,0,0,1-13.74,7.23,54.54,54.54,0,0,1-17.1,2.58q-23.16,0-35.88-13.92-11.76-12.48-11.76-33.73t12.24-33.42q12.24-12.28,34.44-12.3a57.6,57.6,0,0,1,20.76,3.43,36.3,36.3,0,0,1,14.88,10.53Q1065.56,206.56,1065.56,228.81Zm-12-7.92q0-12.63-9.66-22.64t-24.9-10a39.76,39.76,0,0,0-14,2.34,32,32,0,0,0-11.16,7.17q-7.56,8.19-9.72,23.12Z" />
        <path d="M1183.64,234.46q0,39.12-52.68,39.12-31.56,0-46.08-13.44-11.88-11-11.88-33.61h14.16q0,36.36,43.8,36.36,21,0,30.36-7.55,7.92-6.5,7.92-20.53t-10.8-19.55q-6.12-3-29.88-7.57-29.88-5.38-38.76-10.92-12.84-8-12.84-27.12,0-18,10.92-26.64,11.76-9.58,40.32-9.59,51.6,0,51.6,40.92h-14.28q0-30.61-38.28-30.61-19.2,0-28.08,6.12-8.4,6.61-8.4,19.33,0,13.31,10.8,18.83,5.76,3.13,33.84,8.64,26.4,4.82,35.64,11Q1183.64,215.51,1183.64,234.46Z" />
        <path d="M1327.29,273.2l-8.65,9.35-20.88-17.37a50.79,50.79,0,0,1-16.08,6.36,101.43,101.43,0,0,1-22.2,2.16q-32.52,0-51.3-19.39t-18.78-50.93q0-32.28,18.6-51.24t51.24-19q16.8,0,29.88,5a57.06,57.06,0,0,1,22.32,15.36q17.28,18.72,17.28,49.81,0,17.4-5.28,30.66a55.89,55.89,0,0,1-16.34,22.51Zm-12.13-69.77a74.21,74.21,0,0,0-3.72-24.39,50.17,50.17,0,0,0-11.52-18.76q-14.76-16.42-40.56-16.43-25.44,0-40.86,15.71t-15.42,43.87q0,27.1,15.54,43.34T1259.36,263a61.63,61.63,0,0,0,22.32-3.83,53.61,53.61,0,0,0,17.88-11.39Q1315.16,231.49,1315.16,203.43Z" />
        <path d="M1433.84,270.22h-96.6V136.77l13.32-.24V259.05H1434Z" />
      </g>
    </svg>
  )
}
