// import * as THREE from 'three'
import React, { useState, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {
  // Environment,
  OrbitControls,
  Html,
  useProgress
} from '@react-three/drei'
// import { TextureLoader } from 'three/src/loaders/TextureLoader'
// import { useSpring, animated, config } from '@react-spring/three'
// import Model from './components/Model'

// const MyRotatingBox = () => {
//   const myMesh = React.useRef()
//   const [active, setActive] = useState(false)

//   useFrame(({ clock }) => {
//     const a = clock.getElapsedTime()
//     myMesh.current.rotation.x = Math.sin(a)
//   })

//   // const springs = useSpring({ scale: active ? 1.5 : 1 })
//   const { scale } = useSpring({
//     scale: active ? 1.5 : 1,
//     config: config.wobbly
//   })

//   return (
//     <animated.mesh
//       // scale={active ? 1.5 : 1}
//       scale={scale}
//       onClick={() => setActive(!active)}
//       ref={myMesh}
//     >
//       <boxBufferGeometry />
//       <meshPhongMaterial color="royalblue" />
//     </animated.mesh>
//   )
// }

const InfoBox = () => {
  const myMesh = React.useRef()
  const [active, setActive] = useState(false)

  // const springs = useSpring({ scale: active ? 1.5 : 1 })
  // const { scale } = useSpring({
  //   scale: active ? 1.5 : 1,
  //   config: config.wobbly
  // })

  return (
    <group>
      <mesh
        // scale={active ? 1.5 : 1}
        scale={1}
        onClick={() => setActive(!active)}
        ref={myMesh}
      >
        <boxGeometry args={[2, 2, 2]} />
        <meshPhongMaterial color="#30524e" />
        {/* <Text
        color="black" // default
        anchorX="center" // default
        anchorY="middle" // default
        position={[0, 0, 1]}
        fontSize={1}
      >
        2AeS
      </Text> */}

        <Html
          // rotation-z={Math.PI / 2}
          style={{
            transition: 'all 0.2s',
            transform: `scale(${2})`
          }}
          className="infobox"
          distanceFactor={1}
          position={[0, 0, 1.001]}
          transform
          occlude
        >
          <h1>Siti web personalizzati</h1>
          <p>
            La nostra proposta per il sito web è incentrata sull’utilizzo di
            framework innovativi e moderni, ovvero infrastrutture e metodi di
            programmazione che rendono il sito veloce da caricare, ottimizzato
            per i telefoni (mobile oriented) e conforme alle linee guida SEO.
            Non utilizziamo piattaforme CMS standard ma personalizziamo fino
            all’ultima riga di codice sulle esigenze dell’utente.
          </p>
        </Html>
        <Html
          rotation-z={Math.PI}
          rotation-x={Math.PI}
          style={{
            transition: 'all 0.2s',
            transform: `scale(${2})`
          }}
          className="infobox"
          distanceFactor={1}
          position={[0, 0, -1.001]}
          transform
          occlude
        >
          <h1>Soluzioni HW/SW dedicate</h1>
          <p>
            Non solo siti web ed e-commerce. Ci occupiamo della realizzazione su
            misura di dasboard di gestione, logistica e integrazioni IoT. Siamo
            rivenditori autorizzati Esprinet e Computer Gross e mettiamo a
            disposizione dei clienti tutto l occorente hadware per realizzare il
            progetto secondo specifiche ed esigenze.
          </p>
        </Html>
        <Html
          rotation-y={Math.PI / 2}
          style={{
            transition: 'all 0.2s',
            transform: `scale(${2})`
          }}
          className="infobox"
          distanceFactor={1}
          position={[1.001, 0, 0]}
          transform
          occlude
        >
          <h1>e-commerce innovativi</h1>
          <p>
            Le nostre soluzione e-commerce, dal punto di vista tecnologico, si
            definiscono “Web App”. Questo significa che non è un normale sito
            web ma una vera e propria App. La nostra web app è un e-commerce
            specificatamente studiato per focalizzare l’attenzione del cliente
            sui prodotti senza distrazioni. Permette, tra le altre cose, di far
            pagare online i clienti tramite strumenti di alto livello come
            stripe by PayPall
          </p>
        </Html>
        <Html
          rotation-y={-Math.PI / 2}
          style={{
            transition: 'all 0.2s',
            transform: `scale(${2})`
          }}
          className="infobox"
          distanceFactor={1}
          position={[-1.001, 0, 0]}
          transform
          occlude
        >
          <h1>Branding e gestione Social</h1>
          <p>
            Riteniamo di fondamentale importanza uniformare su tutti i canali la
            presenza online del nostro cliente. Un’azione coordinata per la
            creazione dei contenuti grafici/video-fotografici, testi, gestione
            del sito web, dei social e di un e-commerce è fondamentale per il
            raggiungimento degli obiettivi. Ecco perchè offriamo soluzioni tutto
            in uno.
          </p>
        </Html>
      </mesh>
    </group>
  )
}

// const Object1 = () => {
//   const gltf = useLoader(GLTFLoader, '/Poimandres.gltf')
//   return <primitive object={gltf.scene} scale={0.4} />
// }

const Loader = () => {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

// const Scene = () => {
//   // const [colorMap, displacementMap, normalMap, roughnessMap, aoMap] =
//   //   useTexture([
//   //     '/PavingStones092_1K-JPG/PavingStones092_1K_Color.jpg',
//   //     '/PavingStones092_1K-JPG/PavingStones092_1K_Displacement.jpg',
//   //     '/PavingStones092_1K-JPG/PavingStones092_1K_NormalDX.jpg',
//   //     '/PavingStones092_1K-JPG/PavingStones092_1K_Roughness.jpg',
//   //     '/PavingStones092_1K-JPG/PavingStones092_1K_AmbientOcclusion.jpg'
//   //   ])
//   const texture = useTexture({
//     map: '/PavingStones092_1K-JPG/PavingStones092_1K_Color.jpg',
//     displacementMap:
//       '/PavingStones092_1K-JPG/PavingStones092_1K_Displacement.jpg',
//     normalMap: '/PavingStones092_1K-JPG/PavingStones092_1K_NormalDX.jpg',
//     roughnessMap: '/PavingStones092_1K-JPG/PavingStones092_1K_Roughness.jpg',
//     aoMap: '/PavingStones092_1K-JPG/PavingStones092_1K_AmbientOcclusion.jpg'
//   })

//   return (
//     <>
//       <ambientLight intensity={0.2} />
//       <directionalLight />
//       <mesh>
//         <sphereGeometry args={[1, 32, 32]} />
//         <meshStandardMaterial
//           displacementScale={0.2}
//           {...texture}
//           // map={colorMap}
//           // displacementMap={displacementMap}
//           // normalMap={normalMap}
//           // roughnessMap={roughnessMap}
//           // aoMap={aoMap}
//         />
//       </mesh>
//     </>
//   )
// }

// const CubeCameraObject = () => {
//   const texture = useTexture({
//     map: '/PavingStones092_1K-JPG/PavingStones092_1K_Color.jpg',
//     displacementMap:
//       '/PavingStones092_1K-JPG/PavingStones092_1K_Displacement.jpg',
//     normalMap: '/PavingStones092_1K-JPG/PavingStones092_1K_NormalDX.jpg',
//     roughnessMap: '/PavingStones092_1K-JPG/PavingStones092_1K_Roughness.jpg',
//     aoMap: '/PavingStones092_1K-JPG/PavingStones092_1K_AmbientOcclusion.jpg'
//   })
//   return (
//     <CubeCamera
//       resolution={256} // Size of the off-buffer (256 by default)
//       frames={Number.POSITIVE_INFINITY} // How many frames it should render (Indefinitively by default)
//       // fog={customFog} // Allows you to pass a Fog or FogExp2 instance for a smaller frustrum
//       near={1}
//       far={1000}
//     >
//       {texture => (
//         <mesh>
//           <sphereGeometry />
//           <meshStandardMaterial envMap={texture} />
//         </mesh>
//       )}
//     </CubeCamera>
//   )
// }

export default function Test2() {
  return (
    <div className="w-screen h-full overflow-hidden scroll-y-">
      <Canvas>
        <Suspense fallback={<Loader />}>
          {/* <MyRotatingBox /> */}

          {/* eslint-disable-next-line unicorn/numeric-separators-style */}
          <ambientLight args={[0xff0000]} intensity={0.5} />

          <pointLight position={[10, 10, 5]} />
          <pointLight position={[-10, -10, -10]} />

          {/* <directionalLight position={[0, 0, 5]} intensity={0.5} /> */}

          {/* <CubeCameraObj /> */}

          {/* <Scene /> */}
          {/* <Model /> */}

          <InfoBox />

          <OrbitControls />

          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
    </div>
  )
}

/* <mesh
scale={active ? 1.5 : 1}
onClick={() => setActive(!active)}
ref={myMesh}
onContextMenu={e => console.log('context menu')}
onDoubleClick={e => console.log('double click')}
onWheel={e => console.log('wheel spins')}
onPointerUp={e => console.log('up')}
onPointerDown={e => console.log('down')}
onPointerOver={e => console.log('over')}
onPointerOut={e => console.log('out')}
onPointerEnter={e => console.log('enter')}
onPointerLeave={e => console.log('leave')}
onPointerMove={e => console.log('move')}
onPointerMissed={() => console.log('missed')}
onUpdate={self => console.log('props have been updated')}
></mesh> */
