import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'
import Logo from './components/Logo'

export default function Legal() {
  return (
    <>
      <div className="w-full mt-4">
        <Link to="/">
          <Logo className="h-24 w-24 mx-auto" />
        </Link>
      </div>
      <div className="pt-16 px-6 lg:px-20 xl:px-56 leading-7 text-justify pb-10">
        <div className="border-b-2 border-branddark">
          <h1 className="text-left md:text-4xl tracking-widest uppercase pb-3 text-lg ">
            NOTE LEGALI
          </h1>
        </div>
        <h2 className="font-bold text-xl pt-5">Finalità del sito</h2>
        <p>
          Questo sito ha la finalità di esporre la storia e i prodotti
          dell&apos;azienda 2AeS Group s.r.l.. Oltre a questo ha lo scopo di
          facilitare il contatto. Le informazioni riportate hanno lo scopo di
          aiutare la comprensione delle attività svolte.
        </p>
        <h2 className="font-bold text-xl pt-5">Responsabilità </h2>
        <p>
          Tutti i contenuti, le informazioni e i concetti riportati sul sito
          sono di proprietà del Titolare del sito. Tutti i diritti sono quindi
          riservati. La diffusione, distribuzione e/o la riproduzione dei
          contenuti o di parti di essi da parte di qualsiasi soggetto è proibita
          ai sensi dell’art. 616 c.p., ai sensi del Regolamento UE2016/679,
          della direttiva UE2019/789 e all’ulteriore e futura normativa
          applicabile in materia di protezione dei dati personali e copyright. I
          testi, le informazioni e i link ad altri siti presenti sul sito hanno
          esclusivamente scopo informativo e non assumono alcun carattere di
          ufficialità, tranne per quelle informazioni relative al funzionamento
          e all’uso dei dati personali e alle informazioni del titolare e dei
          suoi dati. I titolari del sito, il web master ed eventuali
          collaboratori non si assumono alcuna responsabilità per eventuali
          errori, malfunzionamenti e/o omissioni di qualsiasi tipo.
          L’utilizzatore solleva i Titolari del sito da qualsiasi responsabilità
          riguardo possibili danni diretti, indiretti o accidentali derivanti
          dalla lettura o dall’impiego delle informazioni e contenuti presenti
          nel sito I titolari sono manlevati di qualsiasi responsabilità
          derivante dalla presenza e dal funzionamento di risorse di terzi
          presenti direttamente o indirettamente sul sito (come esempio, non
          esaustivo, inserzioni pubblicitarie, Widget, CMS, servizi di hosting,
          database)
        </p>
        <h2 className="font-bold text-xl pt-5">Divieti </h2>
        <p>
          I siti possono essere utilizzati solo da utenti che risultano
          maggiorenni rispetto alla legge di residenza dell’utente. Eventuali
          utenti minori devono lasciare il sito o essere supervisionati da un
          adulto. È severamente vietato utilizzare le piattaforme, i siti e i
          social inerenti per scopi che esulano questi termini e condizioni. Il
          titolare di questi siti e i collaboratori non si assumono nessuna
          responsabilità per l’uso improprio dei servizi. L’uso scorretto o
          illegale delle piattaforme prevede la segnalazione, ove possibile,
          alle forze dell’ordine dell’illecito.
        </p>
        <h2 className="font-bold text-xl pt-5">Controversie </h2>
        <p>
          Qualsiasi controversia dovrà essere risolta nelle sedi e nei modi
          decisi dal Titolare di questo sito. La legge applicabile è quella del
          paese sede del Titolare (Italia, Piemonte, Provincia di Torino). Non
          viene meno il diritto dell’utente di essere tutelato dalla legge del
          suo paese di residenza. Visitare questo sito implica la presa visione
          di queste informazioni e della privacy policy e la loro completa
          accettazione.
        </p>
      </div>
      {/* footer */}
      <Suspense fallback={<div>Caricamento in corso...</div>}>
        <Footer />
      </Suspense>
    </>
  )
}
