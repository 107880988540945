import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'
import Logo from './components/Logo'

export default function Policies() {
  return (
    <>
      <div className="w-full mt-4">
        <Link to="/">
          <Logo className="h-24 w-24 mx-auto" />
        </Link>
      </div>
      <div className="pt-16 px-6 lg:px-20 xl:px-56 leading-7 text-justify pb-10">
        <div className="border-b-2 border-branddark">
          <h1 className="text-left md:text-4xl tracking-widest uppercase pb-3 text-lg ">
            INFORMATIVA PRIVACY
          </h1>
        </div>
        <div className="pt-5">
          Lo scopo del presente documento è di informare la persona fisica (di
          seguito “Interessato”) relativamente al trattamento dei suoi dati
          personali (di seguito “Dati Personali”) raccolti dal titolare del
          trattamento,{' '}
          <span className="font-bold">
            2AeS Group Srl, con sede legale in Corso Carlo e Nello Rosselli 119,
            Torino, 10129 (TO), CF/Partita IVA 12531390016 , indirizzo e-mail
            amministrazione@2aes-gorup.it, (di seguito “Titolare”)
          </span>
          , tramite il sito web www.2aes-group.it (di seguito “Applicazione”).
          Le modifiche e gli aggiornamenti saranno vincolanti non appena
          pubblicati sull&apos;Applicazione. In caso di mancata accettazione
          delle modifiche apportate all&apos;Informativa Privacy,
          l&apos;Interessato è tenuto a cessare l&apos;utilizzo di questa
          Applicazione e può richiedere al Titolare di cancellare i propri Dati
          Personali.
          <h2 className="font-bold text-xl pt-5">
            1 Categorie di Dati Personali trattati{' '}
          </h2>
          Il Titolare tratta le seguenti tipologie di Dati Personali forniti
          volontariamente dall&apos;Interessato:
          <ul className="list-inside list-disc pl-5">
            <li>
              Dati di contatto: nome, cognome, indirizzo, e-mail, telefono,
              immagini, credenziali di autenticazione, eventuali ulteriori
              informazioni inviate dall&apos;Interessato, etc.{' '}
            </li>
            Il Titolare tratta le seguenti tipologie di Dati Personali raccolti
            in maniera automatizzata:
            <li>
              Dati tecnici: Dati Personali prodotti dai dispositivi, dalle
              applicazioni, dagli strumenti e dai protocolli utilizzati, quali
              ad esempio, informazioni sul dispositivo utilizzato, indirizzi IP,
              tipo di browser, tipo di provider Internet (ISP). Tali Dati
              Personali possono lasciare tracce che, in particolare se combinate
              con identificativi univoci e altre informazioni ricevute dai
              server, possono essere utilizzate per creare profili delle persone
              fisiche{' '}
            </li>
            <li>
              {' '}
              Dati di navigazione e di utilizzo dell&apos;Applicazione: quali,
              ad esempio, pagine visitate, numero di clic, azioni compiute,
              durata delle sessioni, etc. Il mancato conferimento da parte
              dell&apos;Interessato dei Dati Personali per i quali sussiste
              l&apos;obbligo legale, contrattuale o qualora costituiscano
              requisito necessario per la conclusione del contratto con il
              Titolare, comporterà l&apos;impossibilità del Titolare di
              instaurare o proseguire il rapporto con l&apos;Interessato.
            </li>
            L&apos;Interessato che comunichi al Titolare Dati Personali di terzi
            è direttamente ed esclusivamente responsabile della loro
            provenienza, raccolta, trattamento, comunicazione o diffusione.
          </ul>
          <h2 className="font-bold text-xl pt-5">
            {' '}
            2 Cookie e tecnologie simili{' '}
          </h2>
          <div className="pl-5">
            L&apos;Applicazione usa cookie, web beacon, identificatori univoci e
            altre analoghe tecnologie per raccogliere Dati Personali
            dell&apos;Interessato sulle pagine, sui collegamenti visitati e
            sulle altre azioni che si eseguono quando l&apos;Interessato
            utilizza l&apos;Applicazione. Essi vengono memorizzati per essere
            poi trasmessi alla successiva visita dell&apos;Interessato. Si può
            prendere visione della Cookie Policy completa al seguente indirizzo:
            2aes-group.it/cookie-policy
          </div>
          <h2 className="font-bold text-xl pt-5">
            {' '}
            3 Base giuridica e finalità del trattamento{' '}
          </h2>
          Il trattamento dei Dati Personali è necessario:
          <ul className="list-inside list-disc pl-5">
            <li>
              per l&apos;esecuzione del contratto con l&apos;Interessato e
              precisamente:{' '}
            </li>
            <ul className="list-inside list-decimal pl-8">
              <li>
                {' '}
                adempimento di ogni obbligo derivante dal rapporto
                precontrattuale o contrattuale con l&apos;Interessato{' '}
              </li>
              <li>
                {' '}
                registrazione e autenticazione dell&apos;Interessato: per
                consentire all&apos;Interessato di registrarsi
                sull&apos;Applicazione, accedere e essere identificato anche
                tramite piattaforme esterne{' '}
              </li>
              <li>
                {' '}
                supporto e contatto con l&apos;Interessato: per rispondere alle
                richieste dell&apos;Interessato{' '}
              </li>
            </ul>
            <li>per obbligo di legge e precisamente: </li>
            <ul className="list-inside list-decimal pl-8">
              <li>
                l&apos;adempimento di qualunque obbligo previsto dalle vigenti
                normative, leggi e regolamenti, in particolare, in materia
                tributaria e fiscale{' '}
              </li>
            </ul>
            <li> sulla base del legittimo interesse del Titolare, per: </li>
            <ul className="list-inside list-decimal pl-8">
              <li>
                {' '}
                finalità di marketing via email di prodotti e/o servizi del
                titolare per vendere direttamente i prodotti o servizi del
                Titolare utilizzando l&apos;e-mail fornita dall&apos;Interessato
                nel contesto della vendita di un prodotto o servizio analogo a
                quello oggetto della vendita{' '}
              </li>

              <li>
                {' '}
                gestione, ottimizzazione e monitoraggio dell&apos;infrastruttura
                tecnica: per identificare e risolvere eventuali problemi
                tecnici, per migliorare le performance dell&apos;Applicazione,
                per gestire e organizzare le informazioni in un sistema
                informatico (es. server, database, etc.){' '}
              </li>
              <li>
                {' '}
                statistica con dati anonimi: per effettuare analisi statistiche
                su dati aggregati e anonimi per analizzare i comportamenti
                dell&apos;Interessato, per migliorare i prodotti e/o i servizi
                forniti dal Titolare e meglio soddisfare le aspettative
                dell&apos;Interessato{' '}
              </li>
            </ul>
            <li> sulla base del consenso dell&apos;Interessato, per: </li>
            <ul className="list-inside list-decimal pl-8">
              <li>
                {' '}
                profilazione dell&apos;Interessato per fini di marketing: per
                fornire all&apos;Interessato informazioni sui prodotti e/o
                servizi del Titolare attraverso un trattamento automatizzato
                finalizzato alla raccolta di informazioni personali con lo scopo
                di prevedere o valutare le sue preferenze o comportamenti{' '}
              </li>
              <li>
                {' '}
                retargeting e remarketing: per raggiungere con un annuncio
                pubblicitario personalizzato l&apos;Interessato che ha già
                visitato o ha dimostrato interesse per i prodotti e/o servizi
                offerti dall&apos;Applicazione utilizzando i suoi Dati
                Personali. L&apos;Interessato può effettuare l&apos;opt-out
                visitando la pagina della Network Advertising Initiative{' '}
              </li>
              <li>
                {' '}
                finalità di marketing di prodotti e/o servizi del Titolare: per
                inviare informazioni o materiali commerciali e/o promozionali,
                per effettuare attività di vendita diretta di prodotti e/o
                servizi del Titolare o per compiere ricerche di mercato con
                modalità automatizzate e tradizionali{' '}
              </li>
            </ul>
            Sulla base del legittimo interesse del Titolare, l&apos;Applicazione
            permette di effettuare interazioni con piattaforme esterne o social
            network il cui trattamento dei Dati Personali è regolato dalle
            rispettive informative privacy alle quali si prega di fare
            riferimento. Le interazioni e le informazioni acquisite da questa
            Applicazione sono in ogni caso soggette alle impostazioni privacy
            che l&apos;Interessato ha scelto su tali piattaforme o social
            network. Queste informazioni U+2013 in assenza di un specifico
            consenso al trattamento per ulteriori finalità U+2013 vengono
            utilizzate al solo fine di consentire la fruizione
            dell&apos;Applicazione e fornire le informazioni e i servizi
            richiesti. I Dati Personali dell&apos;Interessato possono inoltre
            essere utilizzati dal Titolare per tutelarsi in giudizio avanti le
            sedi giudiziarie competenti.
          </ul>
          <h2 className="font-bold text-xl pt-5">
            {' '}
            4 Modalità di trattamento e destinatari dei Dati Personali
          </h2>
          Il trattamento dei Dati Personali viene effettuato mediante strumenti
          cartacei e informatici con modalità organizzative e con logiche
          strettamente correlate alle finalità indicate e mediante
          l&apos;adozione di adeguate misure di sicurezza.
          <br />I Dati Personali sono trattati esclusivamente da:
          <ul className="list-inside list-disc pl-5">
            <li>
              {' '}
              persone autorizzate dal Titolare del trattamento dei Dati
              Personali che si sono impegnate alla riservatezza o abbiano un
              adeguato obbligo legale di riservatezza;
            </li>
            <li>
              {' '}
              soggetti che operano in autonomia come distinti titolari del
              trattamento o da soggetti designati quali responsabili del
              trattamento dal Titolare al fine di svolgere tutte le attività di
              trattamento necessarie a perseguire le finalità di cui alla
              presente informativa (ad esempio, partner commerciali, consulenti,
              società informatiche, fornitori di servizi, hosting provider);{' '}
            </li>
            <li>
              {' '}
              soggetti o enti a cui è obbligatorio comunicare i Dati Personali
              per obbligo di legge o per ordine delle autorità.
            </li>{' '}
            <br />I soggetti sopra elencati sono tenuti a utilizzare le garanzie
            appropriate per proteggere i Dati Personali e possono accedere solo
            a quelli necessari per eseguire i compiti a loro assegnati. I Dati
            Personali non verranno diffusi indiscriminatamente in alcun modo.
          </ul>
          <h2 className="font-bold text-xl pt-5"> 5 Luogo</h2>I Dati Personali
          non saranno oggetto di alcun trasferimento al di fuori del territorio
          dello Spazio Economico Europeo (SEE).
          <h2 className="font-bold text-xl pt-5">
            6 Periodo di conservazione dei Dati Personali{' '}
          </h2>
          I Dati Personali saranno conservati per il periodo di tempo necessario
          ad adempiere alle finalità per i quali sono stati raccolti, in
          particolare:
          <ul className="list-inside list-disc pl-5">
            <li>
              {' '}
              per finalità relative all&apos;esecuzione del contratto tra il
              Titolare e l&apos;Interessato, saranno conservati per tutta la
              durata del rapporto contrattuale e, dopo la cessazione, per il
              periodo di prescrizione ordinario pari a 10 anni. Nel caso di
              contenzioso giudiziale, per tutta la durata dello stesso, fino
              all&apos;esaurimento dei termini di esperibilità delle azioni di
              impugnazione{' '}
            </li>
            <li>
              {' '}
              per finalità relative al legittimo interesse del Titolare, saranno
              conservati fino al compimento di tale interesse{' '}
            </li>
            <li>
              {' '}
              per l&apos;adempimento di un obbligo di legge, per ordine di
              un&apos;autorità e per la tutela in giudizio, saranno conservati
              nel rispetto delle tempistiche previste da detti obblighi,
              normative e comunque sino al compimento del termine prescrizionale
              previsto dalle norme in vigore{' '}
            </li>
            <li>
              {' '}
              per finalità basate sul consenso dell&apos;Interessato, saranno
              conservati sino alla revoca del consenso
            </li>{' '}
            <br />
            Al termine del periodo di conservazione, tutti i Dati Personali
            saranno cancellati o conservati in una forma che non consenta
            l&apos;identificazione dell&apos;Interessato.
          </ul>
          <h2 className="font-bold text-xl pt-5">
            {' '}
            7 Diritti dell&apos;Interessato{' '}
          </h2>
          Gli Interessati possono esercitare determinati diritti con riferimento
          ai Dati Personali trattati dal Titolare. In particolare,
          l&apos;Interessato ha il diritto di:
          <ul className="list-inside list-disc pl-5">
            <li>
              {' '}
              essere informato sul trattamento dei propri Dati Personali{' '}
            </li>
            <li> revocare il consenso in ogni momento </li>
            <li> limitare il trattamento dei propri Dati Personali </li>
            <li> opporsi al trattamento dei propri Dati Personali </li>
            <li> accedere ai propri Dati Personali </li>
            <li>
              {' '}
              verificare e chiedere la rettifica dei propri Dati Personali{' '}
            </li>
            <li>
              ottenere la limitazione del trattamento dei propri Dati Personali
            </li>
            <li> ottenere la cancellazione dei propri Dati Personali </li>
            <li> trasferire i propri Dati Personali ad altro titolare </li>
            <li>
              proporre reclamo all&apos;autorità di controllo della protezione
              dei propri Dati Personali e/o agire in sede giudiziale.
            </li>
          </ul>
        </div>
        <p>
          Per esercitare i propri diritti, gli Interessati possono indirizzare
          una richiesta al seguente indirizzo e-mail
          amministrazione@2aes-group.it. Le richieste saranno prese in carico
          dal Titolare immediatamente ed evase nel più breve tempo possibile, in
          ogni caso entro 30 giorni. Ultimo aggiornamento: 11/1/2022
        </p>
      </div>
      {/* footer */}
      <Suspense fallback={<div>Caricamento in corso...</div>}>
        <Footer />
      </Suspense>
    </>
  )
}
