import { Image, Transformation } from 'cloudinary-react'
import LogoCgross from './LogoCgross'
import LogoNode from './LogoNode'
import LogoPosgres from './LogoPosgres'
import LogoEsprinet from './LogoEsprinet'

export default function Partners() {
  return (
    <section className="shadow-md ">
      <div className="pt-10 ">
        <h1 className="text-center font-raleway text-brand font-normal text-4xl sm:text-5xl md:text-6xl leading-10 tracking-tight">
          Partner, Tecnologie e certificati
        </h1>
        <p className="text-xs text-center text-gray-300 pt-3">
          Tutti i marchi sono registrati e sono di proprietà delle singole
          aziende citate. Non si intende nessun endorsment da parte loro nei
          confronti dell&apos;azienda.
        </p>
      </div>
      <div className="container px-5 py-5 mx-auto lg:px-6">
        <div className="mx-auto text-center">
          <div className="grid grid-rows-2 grid-flow-col gap-4 mx-auto lg:grid-rows-none lg:grid-cols-4 lg:grid-flow-row items-center">
            <div>
              <LogoNode />
            </div>
            <div>
              <LogoEsprinet />
            </div>
            <div>
              <LogoCgross />
            </div>
            <div>
              <LogoPosgres />
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-center font-raleway text-2xl sm:text-3xl md:text-4xl leading-8 tracking-tight text-brand">
        Certificati
      </h2>

      <div className="grid items-center justify-items-center grid-rows-1 grid-cols-2 lg:grid-cols-4 px-2 gap-4">
        <div className="hidden lg:block" />
        <div className="max-w-xs">
          <Image
            alt="marketing digital training"
            publicId="google_digital_training_dctjjb.png"
            className="object-cover"
            cloudName="group-2aes"
            loading="lazy"
            responsive
            width="auto"
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
        <div className="max-w-xs">
          <Image
            alt="the thins network fundamentals"
            publicId="things_network_hvs3vt.png"
            className="object-cover"
            cloudName="group-2aes"
            loading="lazy"
            responsive
            width="auto"
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
      </div>
    </section>
  )
}
