// import { Fragment } from 'react'
// import Image from 'next/image'
// import { Popover, Transition } from '@headlessui/react'
import { useEffect } from 'react'
import {
  CodeIcon,
  HashtagIcon,
  ShoppingBagIcon,
  AdjustmentsIcon,
  GlobeAltIcon,
  LockClosedIcon
  // GlobeAltIcon,
  // LightningBoltIcon,
  // ScaleIcon
} from '@heroicons/react/outline'
// eslint-disable-next-line import/no-named-as-default
import addReveal from '../lib/Reveal'

// const navigation = [
//   { name: 'ciao', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' }
// ]

const features = [
  {
    name: 'SITI WEB PERSONALIZZATI',
    description:
      'Realizziamo i SITI WEB utilizzando infrastrutture e metodi di programmazione innovativi e moderni per renderli VELOCI da caricare, ottimizzati per i telefoni (mobile oriented) e conformi alle linee guida SEO. Ah, e anche BELLI! Non utilizziamo modelli standard ma personalizziamo fino all’ultima riga di codice. La progettazione grafica viene realizzata da GRAFICI PIGNOLI, tenendo conto delle tue esigenze e analizzando i grandi competitor del settore di riferimento, per essere allineati a quello che propone il mercato.',
    icon: GlobeAltIcon
  },
  {
    name: 'E-COMMERCE E DIGITALIZZAZIONE',
    description:
      'I nostri e-commerce sono delle vere e proprie “WEB APP”, studiati per focalizzare l’attenzione del cliente sui prodotti SENZA DISTRAZIONI, ma mantenendo il focus anche sulla tua artigianalità e territorialità. Creiamo, per te, una VETRINA web BELLA, FACILE e FUNZIONALE che ti aiuterà ad innovare il tuo metodo di vendita. Una piattaforma così reattiva che anche i gesti più semplici ti stupiranno. Tra le altre cose, potrai accettare PAGAMENTI ONLINE tramite strumenti di alto livello come Stripe by PayPal, Apple Pay e tanti altri. Digitalizziamo noi per te la tua attività, trasportandola online. ',
    icon: ShoppingBagIcon
  },
  {
    name: 'BRANDING, SOCIAL, VIDEO/FOTO',
    description:
      'Riteniamo di fondamentale importanza UNIFORMARE su tutti i canali la TUA presenza online. Offriamo PACCHETTI TUTTO IN UNO per il raggiungimento degli obiettivi: creazione dei contenuti grafici/video-fotografici, creazione dei testi, gestione del sito web, dei social e di un e-commerce è fondamentale. Ci occupiamo specificatamente di gestire i social, dal piano editoriale alla pubblicazione quotidiana e continuativa. Inoltre uniformiamo la struttura Google My Business e realizziamo ANNUNCI SPONSORIZZATI su Google, Facebook e Instagram.',
    icon: HashtagIcon
  },
  {
    name: 'PIATTAFORME DI GESTIONE ORDINI',
    description:
      'I nostri nuovi sistemi di GESTIONE ORDINI, abbinati alla vetrina dei tuoi prodotti, sono il più grande scatto in avanti di sempre. Una piattaforma che ti fornisce nuovi metodi di vendita, per attrarre nuovi clienti o semplificare la vita a quelli più fedeli. Ed è super intelligente. La PIATTAFORMA-NEGOZIANTE ti permette di GESTIRE gli ordini ricevuti, vedere le STATISTICHE di vendita, gestire la vetrina digitale e tanto altro! Grazie a lunghi studi e analisi, tutto è pensato per essere MENO INVASIVO POSSIBILE nel normale svolgimento della tua attività.',
    icon: AdjustmentsIcon
  },
  {
    name: 'Sicurezza digitale',
    description:
      "La Cybersecurity è per noi prioritaria. Le infrastrutture ed i sistemi informatici sono fondamentali per l'industria moderna ed analizzare gli assets critici per la sicurezza dell'azienda può salvaguardarla da attacchi esterni o interni, garantendole integrità e continuità. Supportiamo le micro e piccole imprese nella loro crescita, analizzando anche le possibili problematiche di sicurezza, e fornendo soluzioni pronte all'uso ed economiche.",
    icon: LockClosedIcon
  },
  {
    name: 'Soluzioni HW/SW dedicate',
    description:
      'Non solo siti web ed e-commerce. Come partner tecnologico ci occupiamo anche della realizzazione su misura di dashboard di gestione, logistica e integrazioni IoT (ad esempio per il monitoraggio ambientale in ambito Food-tech). Siamo rivenditori autorizzati Esprinet e Computer Gross e mettiamo a disposizione dei clienti tutto l’occorrente hardware per realizzare il progetto secondo le specifiche esigenze.',
    icon: CodeIcon
  }
]

export default function Servizi() {
  useEffect(() => {
    addReveal()
  }, [])
  return (
    <section className=" bg-brand-hot pb-16" id="servizi">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="lg:text-left">
          <h1 className="reveal_from_left text-center font-raleway font-normal text-brand md:text-left text-4xl sm:text-5xl md:text-6xl leading-8 tracking-tight text-brand-shadow">
            I nostri Servizi
          </h1>
          <p className="mt-10 text-xl text-whiteBrand lg:mx-auto text-center md:text-left">
            La nostra forza è studiare, comprendere e ottimizzare le esigenze
            del cliente per trasferirle all’interno delle piattaforme da noi
            realizzate.
          </p>
          <div className="max-w-sm rounded-md shadow-md shadow-shadowBrand hover:shadow-inner buttonflo mt-4">
            <a
              href="/Comelavoriamo"
              className="flex justify-center font-raleway text-base tracking-wider
                   font-extrabold umami--click--more-info"
            >
              più dettagli
            </a>
            <div className="liquid" />
          </div>
        </div>
        <div className="mt-10 overflow-hidden">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 overflow-hidden">
            {features.map(feature => (
              <div
                key={feature.name}
                className=" relative reveal reveal_from_left"
              >
                <dt className="h-12">
                  <div className=" absolute shadow-md border-2 flex items-center justify-center h-12 w-12 rounded-md bg-brand text-whiteBrand">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 h-full font-Oxygen flex items-center text-xl leading-6 font-semibold text-brand">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-3 text-base font-Oxygen text-brand text-justify">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  )
}
