import * as THREE from 'three'
import { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {
  useCursor,
  // Environment,
  Html,
  useProgress,
  Text,
  MeshReflectorMaterial
} from '@react-three/drei'

export default function Test5() {
  // const overlay = useRef()
  // const caption = useRef()
  // const scroll = useRef(0)

  return (
    // <div className="relative overflow-auto select-none h-full">
    <>
      <Canvas
        shadows
        // onCreated={state => state.events.connect(overlay.current)}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY
          })
        }}
      >
        <ambientLight intensity={1} />
        <Suspense fallback={<Loader />}>
          {/* <Model4 scroll={scroll} />
          <Environment preset="city" /> */}
          <Gallery />
        </Suspense>
      </Canvas>
      {/* <Overlay4 ref={overlay} caption={caption} scroll={scroll} /> */}
    </>
  )
}

const Loader = () => {
  const { progress } = useProgress()
  return <Html center>{progress.toFixed(0)} % loaded</Html>
}

// eslint-disable-next-line unicorn/numeric-separators-style
const GOLDENRATIO = 1.61803398875

const Gallery = () => {
  const captions = [
    // // Front
    // { position: [0, 0, 1.5], rotation: [0, 0, 0], content: 'ciaociaociao' },
    // // Back
    // { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], content: 'ciaociaociao' },
    // { position: [0.8, 0, -0.6], rotation: [0, 0, 0], content: 'ciaociaociao' },
    // Left
    {
      name: 'siti',
      // position: [-1.75, 0, 0.25],
      position: [0, 0, 0.5],
      // rotation: [0, Math.PI / 2.5, 0],
      rotation: [0, 0, 0],
      captionHead: 'Siti web personalizzati',
      captionContent:
        'La nostra proposta per il sito web è incentrata sull’utilizzo di framework innovativi e moderni, ovvero infrastrutture e metodi di programmazione che rendono il sito veloce da caricare, ottimizzato per i telefoni (mobile oriented) e conforme alle linee guida SEO. Non utilizziamo piattaforme CMS standard ma personalizziamo fino all’ultima riga di codice sulle esigenze dell’utente.'
    },
    {
      name: 'ecomm',
      position: [-1, 0, 1.3],
      rotation: [0, Math.PI * 0.3, 0],
      captionHead: 'e-commerce innovativi',
      captionContent:
        'Le nostre soluzione e-commerce, dal punto di vista tecnologico, si definiscono “Web App”. Questo significa che non è un normale sito web ma una vera e propria App. La nostra web app è un e-commerce specificatamente studiato per focalizzare l’attenzione del cliente sui prodotti senza distrazioni. Permette, tra le altre cose, di far pagare online i clienti tramite strumenti di alto livello come stripe by PayPall'
    },
    {
      name: 'hwsw',
      position: [1, 0, 1.3],
      rotation: [0, -Math.PI * 0.3, 0],
      captionHead: 'Soluzioni HW/SW dedicate',
      captionContent:
        'Non solo siti web ed e-commerce. Ci occupiamo della realizzazione su misura di dasboard di gestione, logistica e integrazioni IoT. Siamo rivenditori autorizzati Esprinet e Computer Gross e mettiamo a disposizione dei clienti tutto l occorente hadware per realizzare il progetto secondo specifiche ed esigenze.'
    },
    {
      name: 'ordini',
      position: [-1.35, 0, 2.75],
      rotation: [0, Math.PI / 2.5, 0],
      captionHead: 'Piattaforme di gestione ordini',
      captionContent:
        'Abbinata alla vetrina digitale, dove i clienti possono vedere e comprare i Vostri prodotti, forniamo una piattaforma-negoziante che permette ai nostri clienti di gestire gli ordini ricevuti, vedere le statistiche di vendita, gestire la vetrina digitale e tanto altro'
    },
    // Right
    {
      name: 'social',
      position: [1.35, 0, 2.75],
      rotation: [0, -Math.PI / 2.5, 0],
      captionHead: 'Branding e gestione Social',
      captionContent:
        'Riteniamo di fondamentale importanza uniformare su tutti i canali la presenza online del nostro cliente. Un’azione coordinata per la creazione dei contenuti grafici/video-fotografici, testi, gestione del sito web, dei social e di un e-commerce è fondamentale per il raggiungimento degli obiettivi. Ecco perchè offriamo soluzioni tutto in uno.'
    }
  ]
  return (
    <>
      <color attach="background" args={['#191920']} />
      <fog attach="fog" args={['#191920', 0, 15]} />
      {/* <Environment preset="city" /> */}
      <group position={[0, -0.5, 0]}>
        <Frames captions={captions} />
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            color="#151515"
            depthScale={1.2} // Scale the depth factor (0 = no depth, default = 0)
            maxDepthThreshold={1.4} // Upper edge for the depthTexture interpolation (default = 0)
            minDepthThreshold={0.4} // Lower edge for the depthTexture interpolation (default = 0)
            metalness={0.5}
            mixBlur={1} // How much blur mixes with surface roughness (default = 0), note that this can affect performance
            mixStrength={60} // Strength of the reflections
            resolution={2048} // Off-buffer resolution, lower=faster, higher=better quality
            roughness={1}
            // depthToBlurRatioBias={0.25} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
            // distortion={0} // Amount of distortion based on the distortionMap texture
            // distortionMap={distortionTexture} // The red channel of this texture is used as the distortion map. Default is null
            // mirror={0.5} // Mirror environment, 0 = texture colors, 1 = pick up env colors
          />
        </mesh>
      </group>
    </>
  )
}

function Frames({
  captions,
  q = new THREE.Quaternion(),
  p = new THREE.Vector3()
}) {
  const reference = useRef()
  const clicked = useRef()
  const [activeCard, setActiveCard] = useState()

  useEffect(() => {
    clicked.current = reference.current.getObjectByName(activeCard)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)

      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))

      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCard])

  useFrame((state, dt) => {
    state.camera.position.lerp(p, THREE.MathUtils.damp(0, 1, 3, dt))
    state.camera.quaternion.slerp(q, THREE.MathUtils.damp(0, 1, 3, dt))
  })

  return (
    <group
      ref={reference}
      onClick={event => {
        event.stopPropagation()
        setActiveCard(
          event.object.name.length > 0
            ? event.object.name
            : event.object.parent.name
        )
      }}
      onPointerMissed={() => setActiveCard()}
    >
      {captions.map(props => (
        <TextFrame key={props.name} {...props} />
      ))}
    </group>
  )
}

function TextFrame({
  c = new THREE.Color(),
  name,
  captionHead,
  captionContent,
  ...props
}) {
  const [hovered, hover] = useState(false)
  // const [rnd] = useState(() => Math.random())
  const frame = useRef()

  useCursor(hovered)

  // eslint-disable-next-line no-unused-vars
  useFrame(state => {
    // image.current.material.zoom =
    //   2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    // image.current.scale.x = THREE.MathUtils.lerp(
    //   image.current.scale.x,
    //   0.85 * (hovered ? 0.85 : 1),
    //   0.1
    // )
    // image.current.scale.y = THREE.MathUtils.lerp(
    //   image.current.scale.y,
    //   0.9 * (hovered ? 0.905 : 1),
    //   0.1
    // )
    frame.current.material.color.lerp(
      c.set(hovered ? 'orange' : 'white').convertSRGBToLinear(),
      0.1
    )
  })

  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={event => {
          event.stopPropagation()
          hover(true)
        }}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}
      >
        <boxGeometry />
        <meshStandardMaterial
          color="#151515"
          metalness={0.5}
          roughness={0.5}
          envMapIntensity={2}
        />
        <mesh
          ref={frame}
          raycast={() => {}}
          scale={[0.9, 0.93, 0.9]}
          position={[0, 0, 0.2]}
        >
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        {/* <Html
          className="w-[20vw]"
          distanceFactor={1.0}
          position={[0, 0, 0.6]}
          transform
          occlude
        >
          <h1>{captionHead}</h1>
          <p>{captionContent}</p>
        </Html> */}
        <Text
          lineHeight={1}
          letterSpacing={0}
          textAlign="center"
          materialType="MeshBasicMaterial"
          maxWidth={0.9}
          anchorX="center"
          anchorY="top"
          position={[0, 0.38, 0.7]}
          fontSize={0.055}
          color="black"
          font="/Inter-Bold.woff"
        >
          {captionHead}
        </Text>
        <Text
          // font= "Philosopher"
          // sdfGlyphSize={128}
          lineHeight={1}
          letterSpacing={0}
          textAlign="left"
          materialType="MeshBasicMaterial"
          maxWidth={0.8}
          anchorX="center"
          anchorY="top"
          // position={[0, GOLDENRATIO - 0.5, 0.11]}
          position={[0, 0.26, 0.7]}
          fontSize={0.045}
          color="black"
          font="/Inter-Bold.woff"
        >
          {captionContent}
        </Text>
      </mesh>
    </group>
  )
}
