import gsap from 'gsap'
import { useMemo, useRef } from 'react'

export const useGsapSelector = () => {
  // eslint-disable-next-line unicorn/prevent-abbreviations
  const ref = useRef()
  const q = useMemo(() => gsap.utils.selector(ref), [ref])
  return [q, ref]
}

export const useArrayReference = () => {
  const references = useRef([])
  references.current = []

  return [
    references,
    reference => reference && references.current.push(reference)
  ]
}
