import Footer from './Footercg'

export default function Overlay({ ready, clicked, setClicked }) {
  return (
    <>
      <div
        className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${
          clicked && 'clicked'
        }`}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div onClick={() => ready && setClicked(true)}>
          {!ready ? 'caricamento in corso...' : 'entra'}
        </div>
      </div>
      <Footer
      // link1={<a href="#">link1</a>}
      // link2={
      //   <a href="#">
      //     link2
      //   </a>
      // }
      />
    </>
  )
}
