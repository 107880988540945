// import {
//   BookmarkAltIcon,
//   CalendarIcon,
//   ChartBarIcon,
//   CursorClickIcon,
//   PhoneIcon,
//   PlayIcon,
//   RefreshIcon,
//   ShieldCheckIcon,
//   SupportIcon,
//   ViewGridIcon
// } from '@heroicons/react/outline'
import Mac from './Mac'

// const features = [
//   {
//     name: 'Analytics',
//     href: '#',
//     description:
//       'Get a better understanding of where your traffic is coming from.',
//     icon: ChartBarIcon
//   },
//   {
//     name: 'Engagement',
//     href: '#',
//     description: 'Speak directly to your customers in a more meaningful way.',
//     icon: CursorClickIcon
//   },
//   {
//     name: 'Security',
//     href: '#',
//     description: "Your customers' data will be safe and secure.",
//     icon: ShieldCheckIcon
//   },
//   {
//     name: 'Integrations',
//     href: '#',
//     description: "Connect with third-party tools that you're already using.",
//     icon: ViewGridIcon
//   },
//   {
//     name: 'Automations',
//     href: '#',
//     description:
//       'Build strategic funnels that will drive your customers to convert',
//     icon: RefreshIcon
//   }
// ]
// const callsToAction = [
//   { name: 'Watch Demo', href: '#', icon: PlayIcon },
//   { name: 'Contact Sales', href: '#', icon: PhoneIcon }
// ]
// const resources = [
//   {
//     name: 'Help Center',
//     description:
//       'Get all of your questions answered in our forums or contact support.',
//     href: '#',
//     icon: SupportIcon
//   },
//   {
//     name: 'Guides',
//     description:
//       'Learn how to maximize our platform to get the most out of it.',
//     href: '#',
//     icon: BookmarkAltIcon
//   },
//   {
//     name: 'Events',
//     description:
//       'See what meet-ups and other events we might be planning near you.',
//     href: '#',
//     icon: CalendarIcon
//   },
//   {
//     name: 'Security',
//     description: 'Understand how we take your privacy seriously.',
//     href: '#',
//     icon: ShieldCheckIcon
//   }
// ]
// const recentPosts = [
//   { id: 1, name: 'Boost your conversion rate', href: '#' },
//   {
//     id: 2,
//     name: 'How to use search engine optimization to drive traffic to your site',
//     href: '#'
//   },
//   { id: 3, name: 'Improve your customer experience', href: '#' }
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function HeroPage() {
  return (
    <div className="relative bg-gray-50 w-full">
      <main className="w-full">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center">
          <div className="px-8">
            <h1 className="tracking-tight font-extrabold text-gray-900 text-5xl">
              <span className="block">2AeS Group</span>{' '}
              <span className="block text-indigo-600">
                Il tuo Partner Tecnologico
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-gray-500 text-xl">
              Software, Hardware e Logistica
            </p>
            <div className="mt-10 flex justify-center">
              <div className="rounded-md shadow mt-0 ml-3">
                <a
                  href="/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Scopri di più
                </a>
                {/* <Link
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  to="/"
                >
                  Scopri di più
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-72">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src="https://res.cloudinary.com/group-2aes/image/upload/v1640168355/MoleTorinoScura_700x400_rgfxxy.png"
            alt=""
          />
        </div>
        <div className="h-[80vh]">
          <Mac />
        </div>
      </main>
    </div>
  )
}
