import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { Link } from 'react-router-dom'
import Logo from './Logo'

export default function Footer({ link1, link2 }) {
  const today = new Date()

  return (
    <div className="pmndrs-menu">
      <div>
        <Logo
          style={{ width: 42, height: 42 }}
          fill="#f1f1f1"
          backColor="black"
        />
      </div>
      <div>
        <span>
          <b className="text-2xl">2AeS group s.r.l.</b>
        </span>
        <a href="/">linkedin</a>
        <a href="/">@2aesgroup</a>
      </div>
      <div>
        <span>{format(today, 'eeee d LLLL', { locale: it })}</span>
        <span>{format(today, 'uuuu')}</span>
      </div>
      {/* <div>
        <span>Open Source</span>
        <span>Developer collective</span>
      </div> */}
      <div style={{ width: '100%' }} />
      <div className="text-lg font-semibold">
        <b>{link1}</b>
        {link2}
        <Link to="/home2">Home2</Link>
      </div>
    </div>
  )
}
