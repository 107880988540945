import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'
import Logo from './components/Logo'

export default function Cookiepolicy() {
  return (
    <>
      <div className="w-full mt-4">
        <Link to="/">
          <Logo className="h-24 w-24 mx-auto" />
        </Link>
      </div>
      <div className="pt-16 px-6 lg:px-20 xl:px-56 leading-7 text-justify pb-10">
        <div className="border-b-2 border-branddark">
          <h1 className="text-left md:text-4xl tracking-widest uppercase pb-3 text-lg ">
            Cookie Policy
          </h1>
        </div>

        <p>
          La nostra Cookie Policy ha l’intento di descrivere le tipologie di
          cookie utilizzati dalla nostra applicazione www.2aes-group.it (di
          seguito “<span className="font-bold">Applicazione</span>”), le
          finalità, la durata e le modalità con cui l’Utente può gestire i
          cookie presenti.
        </p>
        <p>
          Il titolare del trattamento dei dati personali è 2AeS Group Srl, Corso
          Carlo e Nello Rosselli 119, Torino (10129, TO)
          amministrazione@2aes-group.it (di seguito il “
          <span className="font-bold">Titolare</span>”).
        </p>
        <ol>
          <li>
            <h4 className="font-bold text-xl pt-5">COSA SONO I COOKIE</h4>
            <p>
              I cookie sono stringhe di testo di piccole dimensioni che vengono
              memorizzate sul dispositivo dell&apos;Utente quando naviga in
              rete. I cookie hanno lo scopo di raccogliere e memorizzare
              informazioni relative all’Utente al fine di rendere l’Applicazione
              più semplice, più veloce e più aderente alle sue richieste.
            </p>
            <p>
              Con il termine cookie si vuol far riferimento sia agli stessi
              cookie sia a tutte le tecnologie similari.
            </p>
            <p>
              l&apos;Utente può bloccare i cookie, eliminarli o disattivarli
              nelle impostazioni del dispositivo o del browser come di seguito
              indicato nel paragrafo relativo a “Gestione dei cookie”.
            </p>
            <li>
              <h4 className="font-bold text-xl pt-5">
                TIPOLOGIE DI COOKIE USATI DA QUESTA APPLICAZIONE
              </h4>
              <p>Questa Applicazione usa i seguenti cookie:</p>
              <ul className="list-inside list-disc pl-5">
                <li>
                  <span className="font-bold">
                    Cookie tecnici di prima parte
                  </span>
                  : sono cookie che appartengono al Titolare dell’Applicazione e
                  che raccolgono dati dell’Utente accessibili solo al Titolare.
                  L’Applicazione usa questi cookie per svolgere attività
                  strettamente necessarie al funzionamento dell’Applicazione e
                  all’erogazione del servizio o per raccogliere informazioni, in
                  forma aggregata e anonima, per finalità statistiche. Questi
                  cookie non profilano l’Utente. Per l’uso di questi cookie non
                  è richiesto il consenso dell’Utente.
                </li>
                <li>
                  <span className="font-bold">Cookie di terza parte</span>: sono
                  cookie che appartengono a fornitori esterni e sui quali il
                  Titolare dell’Applicazione non ha il controllo diretto. Questi
                  cookie raccolgono dati dell’Utente che sono accessibili al
                  fornitore del cookie. A seconda della loro finalità questi
                  cookie possono svolgere attività strettamente necessarie al
                  funzionamento dell’Applicazione, raccogliere dati per creare
                  un profilo personale dell’Utente o essere impostati per altre
                  finalità e modalità di trattamento. Per maggiori informazioni
                  si consiglia di consultare con attenzione le privacy policy
                  dei fornitori dei cookie sotto elencati:
                  <ul className="list-inside list-disc pl-5">
                    <li>
                      <span className="font-bold">
                        Facebook Ads monitoraggio conversioni (pixel di
                        Facebook)
                      </span>
                      <p>
                        Il monitoraggio conversioni di Facebook Ads (pixel di
                        Facebook) è un servizio fornito da Facebook, Inc. che
                        fornisce le statistiche e traccia le azioni dell’Utente
                        dopo che ha cliccato sugli annunci gestiti da Facebook.
                      </p>
                      <p>
                        Per l’uso di questi cookie è richiesto il preventivo
                        consenso dell’Utente.
                      </p>
                      <p>Per maggiori informazioni, consultare la pagina:</p>
                      <a
                        href="https://www.facebook.com/about/privacy/"
                        className="break-all underline"
                      >
                        https://www.facebook.com/about/privacy/
                      </a>
                    </li>
                    <li>
                      <span className="font-bold">
                        Google AdWords con monitoraggio conversioni
                      </span>
                      <p>
                        Google AdWords con monitoraggio conversioni è il
                        servizio di Google Inc. che traccia le azioni
                        dell’Utente dopo che ha cliccato sugli annunci
                        pubblicitari gestiti da Google.
                      </p>
                      <p>
                        Per l’uso di questi cookie è richiesto il preventivo
                        consenso dell’Utente.
                      </p>
                      <p>Per maggiori informazioni, consultare la pagina:</p>
                      <a
                        href="https://policies.google.com/privacy"
                        className="break-all underline"
                      >
                        https://policies.google.com/privacy
                      </a>
                    </li>
                    <li>
                      <span className="font-bold">
                        Google Analytics con IP anonimizzato
                      </span>
                      <p>
                        Google Analytics è un servizio di analisi statistica
                        fornito da Google Inc. che usa i cookie per misurare le
                        visite e l’utilizzo dell’Applicazione da parte degli
                        Utenti. Google dichiara che i dati raccolti non
                        consentono di effettuare profilazione dell’Utente poiché
                        riceve l’indirizzo IP degli Utenti anonimizzato e altri
                        dati anonimi che non possono identificare l’Utente. Per
                        l’uso di questi cookie non è richiesto il preventivo
                        consenso dell’Utente.
                      </p>
                      <p>Per maggiori informazioni, consultare la pagina:</p>
                      <a
                        href="https://policies.google.com/privacy"
                        className="break-all underline"
                      >
                        https://policies.google.com/privacy
                      </a>
                    </li>
                    <li>
                      <span className="font-bold">Matomo</span>
                      <p>
                        monitoraggio delle performance della pubblicità su
                        Facebook
                      </p>
                      <p>
                        Per maggiori informazioni, consultare la pagina:
                        https://matomo.org/privacy-policy/
                      </p>
                      <p>
                        Per l’uso di questi cookie è richiesto il preventivo
                        consenso dell’Utente.
                      </p>
                    </li>
                    <li>
                      <span className="font-bold">Plausible</span>
                      <p>
                        Monitoraggio delle performance della pubblicità su
                        Facebook
                      </p>
                      <p>
                        Per maggiori informazioni, consultare la pagina:{' '}
                        <a
                          href="https://plausible.io/privacy"
                          className="underline break-all"
                        >
                          https://plausible.io/privacy
                        </a>
                      </p>
                      <p>
                        Non vengono memorizzati cookie di alcuna natura. Per
                        maggiori informazioni consultare:{' '}
                        <a
                          href="https://plausible.io/data-policy"
                          className="underline break-all"
                        >
                          https://plausible.io/data-policy
                        </a>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h4 className="font-bold text-xl pt-5">DURATA DEI COOKIE</h4>
              <p>
                I cookie hanno una durata dettata dalla data di scadenza o da
                un’azione specifica, come la chiusura del browser impostata al
                momento dell’installazione. I cookie possono essere:
              </p>
              <ul className="list-inside list-disc pl-5">
                <li>
                  <span className="font-bold">temporanei o di sessione: </span>
                  sono utilizzati per archiviare informazioni temporanee,
                  consentono di collegare le azioni eseguite durante una
                  sessione specifica e vengono rimossi dal dispositivo
                  dell’Utente alla chiusura del browser;
                </li>
                <li>
                  <span className="font-bold">persistenti: </span>
                  sono utilizzati per archiviare informazioni, ad esempio il
                  nome e la password di accesso, in modo da evitare che l’Utente
                  debba digitarli nuovamente ogni volta che visita un sito
                  specifico. Questi rimangono memorizzati nel dispositivo
                  dell’Utente anche dopo aver chiuso il browser.
                </li>
              </ul>
              <p>
                I cookie di profilazione sono memorizzati sul dispositivo
                dell’Utente per un periodo non superiore a 12 mesi. Allo scadere
                di tale termine verrà riproposto all’Utente il banner tramite
                cui sarà necessario manifestare nuovamente il consenso
                all’utilizzo di questi cookie.
              </p>
            </li>
            <li>
              <h4 className="font-bold text-xl pt-5">GESTIONE DEI COOKIE</h4>
              <p>
                L’Utente può gestire le preferenze relative ai cookie
                direttamente all&apos;interno del proprio browser ed impedire
                che terze parti possano installare i cookies. Tramite le
                preferenze del browser è inoltre possibile eliminare i cookie
                installati in passato, incluso il cookie in cui è stato
                eventualmente salvato il consenso. Disabilitando tutti i cookie,
                il funzionamento di questa Applicazione potrebbe risultare
                compromesso.
              </p>
              <p>
                l&apos;Utente può cancellare tutti i cookie presenti sul suo
                dispositivo semplicemente selezionando, sul proprio browser, le
                impostazioni che consentono di rifiutarli. Ogni browser ha
                specifiche istruzioni di configurazione che possono essere
                trovati sul sito internet del fornitore del browser ai seguenti
                link:
              </p>
              <ul className="list-inside list-disc pl-5 text-left">
                <li>
                  Apple Safari:
                  <br />
                  <a
                    href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
                    className="break-all underline"
                  >
                    https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/
                  </a>
                </li>
                <li>
                  Google Chrome:
                  <br />
                  <a
                    href="https://support.google.com/chrome/answer/95647"
                    className="break-all underline"
                  >
                    https://support.google.com/chrome/answer/95647
                  </a>
                </li>
                <li>
                  Mozilla Firefox:
                  <br />
                  <a
                    href="https://support.mozilla.org/kb/enable-and-disable-cookies-website-preferences"
                    className="break-all underline"
                  >
                    https://support.mozilla.org/kb/enable-and-disable-cookies-website-preferences
                  </a>
                </li>
                <li>
                  Internet Explorer:
                  <br />
                  <a
                    href="http://windows.microsoft.com/internet-explorer/delete-manage-cookies"
                    className="break-all underline"
                  >
                    http://windows.microsoft.com/internet-explorer/delete-manage-cookies
                  </a>
                </li>
                <li>
                  Microsoft Edge:
                  <br />
                  <a
                    href="https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies"
                    className="break-all underline"
                  >
                    https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies
                  </a>
                </li>
                <li>
                  Opera:
                  <br />
                  <a
                    href="https://www.opera.com/help/tutorials/security/privacy/"
                    className="break-all underline"
                  >
                    https://www.opera.com/help/tutorials/security/privacy/
                  </a>
                </li>
              </ul>
              <p>
                Inoltre si può procedere alla cancellazione dei
                <span className="font-bold"> cookie di terze parti </span>
                chiedendo l’opt-out direttamente alle terze parti o tramite il
                sito
                <br />
                <a
                  href="http://www.youronlinechoices.com"
                  className="break-all underline"
                >
                  http://www.youronlinechoices.com
                </a>
                , dove è possibile gestire le preferenze di tracciamento della
                maggior parte degli strumenti pubblicitari.
              </p>
              <p>
                Per modificare le impostazioni relative ai cookie Flash si può
                cliccare sul seguente link
                <br />
                <a
                  href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                  className="break-all underline"
                >
                  https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
                </a>
                .
              </p>
            </li>
            <li>
              <h4 className="font-bold text-xl pt-5">DIRITTI DELL’UTENTE</h4>
              <p>
                Gli Utenti possono esercitare determinati diritti con
                riferimento ai dati personali trattati dal Titolare. In
                particolare, l’Utente ha il diritto di:
              </p>
              <ul className="list-inside list-disc pl-5">
                <li>revocare il consenso in ogni momento;</li>
                <li>opporsi al trattamento dei propri dati personali;</li>
                <li>accedere ai propri dati personali;</li>
                <li>verificare e chiedere la rettificazione;</li>
                <li>ottenere la limitazione del trattamento;</li>
                <li>
                  ottenere la cancellazione o rimozione dei propri dati
                  personali;
                </li>
                <li>
                  ricevere i propri dati personali o farli trasferire ad altro
                  titolare;
                </li>
                <li>
                  proporre reclamo all’autorità di controllo della protezione
                  dei dati personali e/o agire in sede giudiziale.
                </li>
              </ul>
              <p>
                Per esercitare tali diritti, scrivere al Titolare:
                amministrazione@2aes-group.it
              </p>
              <p>
                Le richieste sono effettuate a titolo gratuito ed evase dal
                Titolare nel più breve tempo possibile, in ogni caso entro 30
                giorni.
              </p>
            </li>
          </li>
        </ol>
        <p>
          Le ulteriori informazioni riguardo al trattamento dei dati personali
          si possono trovare nell’informativa privacy al seguente link
          www.2aes-group.it.
        </p>
        <p>Ultimo aggiornamento: 11/1/2022</p>
      </div>
      {/* footer */}
      <Suspense fallback={<div>Caricamento in corso...</div>}>
        <Footer />
      </Suspense>
    </>
  )
}
