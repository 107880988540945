import { lazy, Suspense, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Image, Placeholder, Transformation } from 'cloudinary-react'
import Servizi from './components/Servizi'
import Partners from './components/Partners'
import Chisiamo from './components/ChiSiamo'
import Mac from './Mac'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import { addReveal } from './lib/Reveal'

import Logo from './components/Logo'
// eslint-disable-next-line import/no-named-as-default
import { useGsapSelector } from './lib/GsapHelpers'

const HomeVideo = lazy(() => import('./components/HomeVideo'))

function useArrayReference() {
  const references = useRef([])
  references.current = []

  return [
    references,
    reference => reference && references.current.push(reference)
  ]
}

export default function Home() {
  /*   const [q, reference] = useSelector() */
  const [references, setReference] = useArrayReference()

  const [q, homePageReference] = useGsapSelector()

  useEffect(() => {
    addReveal()
  }, [])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    references?.current?.map(currentReference =>
      gsap.fromTo(
        currentReference.querySelectorAll('.pSection'),
        { yPercent: 10 },
        {
          yPercent: -20,
          ease: 'none',
          scrollTrigger: {
            trigger: currentReference,
            start: 'top bottom',
            end: 'bottom top',
            // markers: true,
            scrub: true
          }
        }
      )
    )
  }, [references])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.to(
      q('.logo'),

      {
        height: '3rem',
        ease: 'none',
        scrollTrigger: {
          trigger: q('.first-page'),
          start: 'top middle',
          end: 'bottom top',
          // markers: true,
          scrub: true
        }
      }
    )

    gsap.to(
      q('.logo'),

      {
        marginLeft: '100%',
        x: '-6rem',

        // ease: 'expo.out',
        scrollTrigger: {
          trigger: q('.scrollSection'),
          start: 'top top',
          end: 'bottom bottom',
          // markers: true,
          scrub: true
        }
      }
    )
  }, [homePageReference, q])

  return (
    <div
      className="relative w-full h-full bg-brand-back"
      ref={homePageReference}
    >
      <article className="relative w-full h-full bg-brand">
        <div
          className="fixed inset-x-0 -ml-1 top-0 h-24 z-50 logo"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          onKeyDown={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          tabIndex={0}
          role="button"
        >
          <Logo className="h-full w-24" color="#30524e" />
        </div>
        {/* prima sezione full screen con video */}
        <section className="relative flex h-full overflow-hidden first-page">
          {/* WRAPPA IL VIDEO */}
          <div className="relative flex flex-col flex-1 text-whiteBrand justify-end z-10 py-4 px-5 max-w-3xl md:ml-24">
            <div className="">
              {/* zona testo */}
              <h1 className="text-6xl sm:text-7xl md:text-8xl leading-3 px-1">
                <span className=" font-Expletus font-semibold tracking-tight">
                  2A
                </span>
                <span className="font-Expletus font-normal text-3xl sm:text-4xl md:text-5xl tracking-tight ">
                  e
                </span>
                <span className="font-Expletus font-semibold tracking-tight pr-2">
                  S
                </span>
                <span className=" font-playfair font-normal text-3xl sm:text-4xl md:text-5xl">
                  group
                </span>

                <span className="block font-Oxygen font-bold text-xl sm:text-2xl md:text-3xl -mt-1">
                  il tuo partner tecnologico
                </span>
              </h1>
              <p className="px-1 py-4 text-sm sm:text-base md:text-xl sm:mx-auto lg:mx-0">
                Vetrine digitali, e-commerce innovativi, branding,
                digitalizzazione e siti web. Semplicità e tecnologia sono la
                nostra filosofia. Territorio e sostenibilità i nostri valori.
              </p>
              {/* zona bottoni */}
              <div className="justify-around sm:justify-start grid grid-cols-2 gap-x-2 sm:gap-x-8">
                <div className="rounded-md shadow-md shadow-shadowBrand hover:shadow-inner buttonflo ">
                  <a
                    href="#contactform"
                    className="w-full flex justify-center umami--click--contactform font-raleway text-base tracking-wider
                   font-extrabold"
                  >
                    contattaci
                  </a>
                  <div className="liquid" />
                </div>
                <div className="rounded-md shadow-md shadow-shadowBrand hover:shadow-inner buttonflo buttonflo1 font-raleway">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#laptop"
                    className="w-full flex justify-center  umami--click--contactform font-raleway text-base tracking-wider
                   font-extrabold"
                  >
                    servizi
                  </a>
                  <div className="liquid liquid1" />
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>

          <Suspense fallback={<div>Caricamento in corso...</div>}>
            <HomeVideo />
          </Suspense>
        </section>
        <div className="relative scrollSection">
          {/* Sezione parallasse */}
          <section
            className="relative bg-brand-hot shadow-inner z-20"
            ref={setReference}
          >
            <div className=" max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center bg-branddark rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28">
              <div className="xl:order-last w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt="mole antonelliana"
                    publicId="MoleTorinoScura_700x400_rgfxxy"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-whiteBrand text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    Il Territorio
                  </h1>
                  <h3 className="font-Open font-bold  text-whiteBrand text-xl md:text-2xl ">
                    Torino e Piemonte
                  </h3>
                </div>
                <p className=" text-whiteBrand text-base text-justify sm:text-lg md:text-xl ">
                  Realizziamo servizi innovativi capaci di supportare i negozi e
                  le piccole e medie imprese commerciali ed alimentari del
                  territorio, per renderle più competitive sul mercato, in un
                  contesto sempre più tecnologico e globalizzato. Contesto che
                  rischia di cancellare le loro tradizioni. Come fare il pane
                  non può cambiare, ma può cambiare come i clienti lo ordinano.
                </p>
              </div>
            </div>
          </section>
          {/*  */}
          <section
            className="relative bg-brand shadow-inner z-30"
            ref={setReference}
          >
            <div className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center bg-brand-hot rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28">
              <div className=" w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-l-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt="programmatori"
                    publicId="pexels-christina-morillo-1181263_dx1npq"
                    className="object-cover"
                    cloudName="group-2aes"
                    loading="lazy"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-branddark text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    L&apos;assistenza
                  </h1>
                  <h3 className="font-Open font-bold  text-branddark text-xl md:text-2xl ">
                    Unico interlocutore
                  </h3>
                </div>
                <p className=" text-branddark text-base text-justify sm:text-lg md:text-xl ">
                  I nostri progetti si basano sull&apos;eccellenza tecnica e
                  sulla chiara comunicazione con il cliente. Come unico
                  interlocutore ci occupiamo di tutto noi, per lasciare al
                  cliente la libertà di svolgere serenamente il suo lavoro.
                  Pianifichiamo, progettiamo, realizziamo e consegniamo.
                </p>
              </div>
            </div>
          </section>

          <section className="relative bg-brand-hot z-40" ref={setReference}>
            <div className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center bg-branddark rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28">
              <div className="xl:order-last w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt="programmatori"
                    publicId="https://res.cloudinary.com/group-2aes/image/upload/v1643900743/realt%C3%A0_virtuale_jag3eo.jpg"
                    className="object-cover"
                    cloudName="group-2aes"
                    loading="lazy"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-whiteBrand text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    Nuova frontiera
                  </h1>
                </div>
                <p className=" text-whiteBrand text-base text-justify sm:text-lg md:text-xl ">
                  Digitalizza la tua attività e trasportala online. Creiamo, per
                  te, siti web e vetrine di e-commerce, con potenti strumenti
                  che ti aiuteranno a gestire la tua routine quotidiana e a
                  innovare. Guarda un esempio di computer grafica.
                </p>
                <div className="rounded-md shadow mt-4">
                  <a
                    href="/test4"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base tracking-wider
                   font-extrabold rounded-md text-brand bg-whiteBrand hover:bg-gray-900 hover:text-whiteBrand md:py-4 md:text-lg md:px-10
                  umami--click--servizi"
                  >
                    Guarda
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* MAC */}
          <div className="h-[80vh]" id="laptop">
            <Mac />
          </div>
          {/* Introduzione servizi */}
          <Suspense fallback={<div>Caricamento in corso...</div>}>
            <Servizi />
          </Suspense>
          {/* Partners */}
          <Suspense fallback={<div>Caricamento in corso...</div>}>
            <Partners />
          </Suspense>
          {/* Chi siamo */}
          <Suspense fallback={<div>Caricamento in corso...</div>}>
            <Chisiamo />
          </Suspense>
        </div>
        {/* contatti */}
        <Suspense fallback={<div>Caricamento in corso...</div>}>
          <ContactForm />
        </Suspense>

        {/* footer */}
        <Suspense fallback={<div>Caricamento in corso...</div>}>
          <Footer />
        </Suspense>
      </article>
    </div>
  )
}
