/* eslint-disable unicorn/numeric-separators-style */
import * as THREE from 'three'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {
  useCursor,
  MeshReflectorMaterial,
  // Image,
  Text,
  // Environment,
  useProgress,
  Html
} from '@react-three/drei'
// import getUuid from 'uuid-by-string'

const GOLDENRATIO = 1.61803398875
const pexel = id =>
  `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`

const Gallery = () => {
  const images = [
    // Front
    { position: [0, 0, 1.5], rotation: [0, 0, 0], url: pexel(1103970) },
    // Back
    { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(416430) },
    { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(310452) },
    // Left
    {
      position: [-1.75, 0, 0.25],
      rotation: [0, Math.PI / 2.5, 0],
      url: pexel(327482)
    },
    {
      position: [-2.15, 0, 1.5],
      rotation: [0, Math.PI / 2.5, 0],
      url: pexel(325185)
    },
    {
      position: [-2, 0, 2.75],
      rotation: [0, Math.PI / 2.5, 0],
      url: pexel(358574)
    },
    // Right
    {
      position: [1.75, 0, 0.25],
      rotation: [0, -Math.PI / 2.5, 0],
      url: pexel(227675)
    },
    {
      position: [2.15, 0, 1.5],
      rotation: [0, -Math.PI / 2.5, 0],
      url: pexel(911738)
    },
    {
      position: [2, 0, 2.75],
      rotation: [0, -Math.PI / 2.5, 0],
      url: pexel(1738986)
    }
  ]
  return (
    <>
      <color attach="background" args={['#191920']} />
      <fog attach="fog" args={['#191920', 0, 15]} />
      {/* <Environment preset="city" /> */}
      <group position={[0, -0.5, 0]}>
        <Frames images={images} />
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={2048}
            mixBlur={1}
            mixStrength={60}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#151515"
            metalness={0.5}
          />
        </mesh>
      </group>
    </>
  )
}

const Loader = () => {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

function Frames({
  // images,
  q = new THREE.Quaternion(),
  p = new THREE.Vector3()
}) {
  const reference = useRef()
  const clicked = useRef()
  const [activeCard, setActiveCard] = useState()

  useEffect(() => {
    clicked.current = reference.current.getObjectByName(activeCard?.name)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })

  useFrame((state, dt) => {
    state.camera.position.lerp(p, THREE.MathUtils.damp(0, 1, 3, dt))
    state.camera.quaternion.slerp(q, THREE.MathUtils.damp(0, 1, 3, dt))
  })

  return (
    <group
      ref={reference}
      onClick={event => {
        event.stopPropagation()
        setActiveCard(event.object)
      }}
      onPointerMissed={() => setActiveCard()}
    >
      {/* {images.map(props => (
        <Frame key={props.url} {...props} />
      ))} */}

      <TextFrame position={[0.7, 0, 0]} rotation={[0, Math.PI / -3.5, 0]} />
      <TextFrame position={[-0.8, 0, -0.6]} rotation={[0, 0, 0]} />
      <TextFrame position={[-1.75, 0, 0.25]} rotation={[0, Math.PI / 2.5, 0]} />
    </group>
  )
}

// function Frame({ url, c = new THREE.Color(), ...props }) {
//   const [hovered, hover] = useState(false)
//   const [rnd] = useState(() => Math.random())
//   const image = useRef()
//   const frame = useRef()
//   const name = getUuid(url)
//   useCursor(hovered)
//   useFrame(state => {
//     image.current.material.zoom =
//       2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
//     image.current.scale.x = THREE.MathUtils.lerp(
//       image.current.scale.x,
//       0.85 * (hovered ? 0.85 : 1),
//       0.1
//     )
//     image.current.scale.y = THREE.MathUtils.lerp(
//       image.current.scale.y,
//       0.9 * (hovered ? 0.905 : 1),
//       0.1
//     )
//     frame.current.material.color.lerp(
//       c.set(hovered ? 'orange' : 'white').convertSRGBToLinear(),
//       0.1
//     )
//   })
//   return (
//     <group {...props}>
//       <mesh
//         name={name}
//         onPointerOver={e => (e.stopPropagation(), hover(true))}
//         onPointerOut={() => hover(false)}
//         scale={[1, GOLDENRATIO, 0.05]}
//         position={[0, GOLDENRATIO / 2, 0]}
//       >
//         <boxGeometry />
//         <meshStandardMaterial
//           color="#151515"
//           metalness={0.5}
//           roughness={0.5}
//           envMapIntensity={2}
//         />
//         <mesh
//           ref={frame}
//           raycast={() => null}
//           scale={[0.9, 0.93, 0.9]}
//           position={[0, 0, 0.2]}
//         >
//           <boxGeometry />
//           <meshBasicMaterial toneMapped={false} fog={false} />
//         </mesh>
//         <Image
//           raycast={() => null}
//           ref={image}
//           position={[0, 0, 0.7]}
//           url={url}
//         />
//       </mesh>
//       <Text
//         maxWidth={0.1}
//         anchorX="left"
//         anchorY="top"
//         position={[0.55, GOLDENRATIO, 0]}
//         fontSize={0.025}
//       >
//         {name.split('-').join(' ')}
//       </Text>
//     </group>
//   )
// }

function TextFrame({ c = new THREE.Color(), ...props }) {
  const [hovered, hover] = useState(false)
  // const [rnd] = useState(() => Math.random())
  const frame = useRef()
  const name = 'scheda_1'

  useCursor(hovered)

  // eslint-disable-next-line no-unused-vars
  useFrame(state => {
    // image.current.material.zoom =
    //   2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    // image.current.scale.x = THREE.MathUtils.lerp(
    //   image.current.scale.x,
    //   0.85 * (hovered ? 0.85 : 1),
    //   0.1
    // )
    // image.current.scale.y = THREE.MathUtils.lerp(
    //   image.current.scale.y,
    //   0.9 * (hovered ? 0.905 : 1),
    //   0.1
    // )
    frame.current.material.color.lerp(
      c.set(hovered ? 'orange' : 'white').convertSRGBToLinear(),
      0.1
    )
  })

  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={event => {
          event.stopPropagation()
          hover(true)
        }}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}
      >
        <boxGeometry />
        <meshStandardMaterial
          color="#151515"
          metalness={0.5}
          roughness={0.5}
          envMapIntensity={2}
        />
        <mesh
          ref={frame}
          raycast={() => {}}
          scale={[0.9, 0.93, 0.9]}
          position={[0, 0, 0.2]}
        >
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
      </mesh>

      <Text
        maxWidth={0.7}
        anchorX="center"
        anchorY="top"
        position={[0, GOLDENRATIO - 0.2, 0.11]}
        fontSize={0.075}
        color="black"
      >
        Soluzioni HW/SW dedicate
      </Text>
      <Text
        maxWidth={0.7}
        anchorX="center"
        anchorY="top"
        position={[0, GOLDENRATIO - 0.5, 0.11]}
        fontSize={0.055}
        color="black"
      >
        Non solo siti web ed e-commerce. Ci occupiamo della realizzazione su
        misura di dasboard di gestione, logistica e integrazioni IoT. Siamo
        rivenditori autorizzati Esprinet e Computer Gross e mettiamo a
        disposizione dei clienti tutto l occorente hadware per realizzare il
        progetto secondo specifiche ed esigenze.
      </Text>
    </group>
  )
}

export default function Test3() {
  return (
    <div className="w-screen h-screen">
      <Canvas
        gl={{ alpha: false }}
        dpr={[1, 1.5]}
        camera={{ fov: 70, position: [0, 2, 15] }}
      >
        <Suspense fallback={<Loader />}>
          {/* <ambientLight args={[0xff0000]} intensity={0.5} />

          <pointLight position={[10, 10, 5]} />
          <pointLight position={[-10, -10, -10]} /> */}
          <Gallery />

          {/* <OrbitControls /> */}

          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
    </div>
  )
}
