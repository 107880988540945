/* eslint-disable no-unused-vars */
export default function LogoCgross({
  color = '#30524e',
  backColor = '#fff',
  ...props
}) {
  return (
    <svg
      id="ae99cec5-b2a4-4108-859a-6b6ce6984778"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 71 10.01"
      {...props}
    >
      <path
        d="M15.92,7.84a2.45,2.45,0,0,1-.82.46,3,3,0,0,1-1,.15,3.14,3.14,0,0,1-.91-.14,2.22,2.22,0,0,1-.79-.42,2.14,2.14,0,0,1-.56-.7,2.19,2.19,0,0,1-.21-1,2.11,2.11,0,0,1,.77-1.7,2.52,2.52,0,0,1,.79-.43,3.14,3.14,0,0,1,.91-.14,3,3,0,0,1,.91.14,2.31,2.31,0,0,1,.79.42L15,5.31a1.31,1.31,0,0,0-.4-.26A1.27,1.27,0,0,0,14.15,5a1,1,0,0,0-.43.09,1.41,1.41,0,0,0-.36.27,1,1,0,0,0-.25.4A1.51,1.51,0,0,0,13,6.2a1.39,1.39,0,0,0,.07.46.9.9,0,0,0,.25.39,1.41,1.41,0,0,0,.36.27,1,1,0,0,0,.43.1,1.28,1.28,0,0,0,.48-.09,1.09,1.09,0,0,0,.4-.27l0,0Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M21,6.2a2.19,2.19,0,0,1-.21,1,2.14,2.14,0,0,1-.56.7,2.31,2.31,0,0,1-.79.42,3.06,3.06,0,0,1-1.83,0,2.22,2.22,0,0,1-.79-.42,2.14,2.14,0,0,1-.56-.7,2.19,2.19,0,0,1-.21-1,2.11,2.11,0,0,1,.77-1.7,2.52,2.52,0,0,1,.79-.43,3.06,3.06,0,0,1,1.83,0,2.34,2.34,0,0,1,.79.43A2.11,2.11,0,0,1,21,6.2m-1.69.87a1,1,0,0,0,.25-.4,1.49,1.49,0,0,0,.07-.47,1.51,1.51,0,0,0-.07-.48.94.94,0,0,0-.25-.4,1,1,0,0,0-.39-.25A1.29,1.29,0,0,0,18.5,5a1.18,1.18,0,0,0-.39.08,1.07,1.07,0,0,0-.64.65,1.51,1.51,0,0,0-.07.48,1.39,1.39,0,0,0,.07.46.9.9,0,0,0,.25.39,1.21,1.21,0,0,0,.39.26,1,1,0,0,0,.39.08,1.23,1.23,0,0,0,.4-.08.85.85,0,0,0,.39-.24"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M25.53,8.38H24.34V6a2.07,2.07,0,0,0-.05-.37.88.88,0,0,0-.16-.32A1,1,0,0,0,23.36,5a1,1,0,0,0-.77.37.79.79,0,0,0-.15.24V8.39H21.25V4h1.19v.4A1.57,1.57,0,0,1,23,4.06a1.62,1.62,0,0,1,.64-.12,1.33,1.33,0,0,1,.88.31,2.39,2.39,0,0,1,.64.79,2,2,0,0,1,.6-.8,1.53,1.53,0,0,1,1-.3,1.91,1.91,0,0,1,.8.17,1.73,1.73,0,0,1,.61.46,2,2,0,0,1,.39.71,3,3,0,0,1,.14.92V8.4H27.42V6.2a3.33,3.33,0,0,0,0-.46.7.7,0,0,0-.18-.39A1,1,0,0,0,26.44,5a1,1,0,0,0-.77.37.79.79,0,0,0-.15.24V8.38Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M33.91,6.2a2.51,2.51,0,0,1-.16.92A2,2,0,0,1,32.6,8.29a2.42,2.42,0,0,1-.91.17A1.87,1.87,0,0,1,30.38,8V9.33l-1.36.9V4h1.36v.47a1.94,1.94,0,0,1,.58-.4,1.73,1.73,0,0,1,.73-.14,2.42,2.42,0,0,1,.91.17,2.13,2.13,0,0,1,.7.46,2.26,2.26,0,0,1,.45.71,2.52,2.52,0,0,1,.16.93m-1.69.87a1,1,0,0,0,.25-.4,1.49,1.49,0,0,0,.07-.47,1.51,1.51,0,0,0-.07-.48.94.94,0,0,0-.25-.4,1.41,1.41,0,0,0-.36-.27A1,1,0,0,0,31.42,5a1.27,1.27,0,0,0-.47.09,1.09,1.09,0,0,0-.4.27,1,1,0,0,0-.18.24V6.83a.94.94,0,0,0,.18.24,1.19,1.19,0,0,0,.88.37,1,1,0,0,0,.44-.09,1.79,1.79,0,0,0,.35-.28"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M34.1,4h1.36V6.2a3.33,3.33,0,0,0,0,.46.63.63,0,0,0,.21.39,1.24,1.24,0,0,0,1.77,0,1,1,0,0,0,.18-.24V4H39V8.38H37.65V8a1.8,1.8,0,0,1-.58.35,2.11,2.11,0,0,1-.73.12,2.42,2.42,0,0,1-.91-.17,2,2,0,0,1-.7-.46,2.15,2.15,0,0,1-.45-.71,2.16,2.16,0,0,1-.18-.92Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <polygon
        points="42.17 3.47 41.14 3.47 41.14 6.79 39.78 6.79 39.78 3.47 39.36 3.47 39.36 2.41 41.14 0.84 41.14 2.41 42.17 2.41 42.17 3.47"
        fill="#1b2a4a"
      />
      <path
        d="M47.14,6.83H43.71a1.31,1.31,0,0,0,.39.42,1,1,0,0,0,.56.17,1.4,1.4,0,0,0,.54-.1.8.8,0,0,0,.39-.3l.88.78a2.4,2.4,0,0,1-.83.49,3.19,3.19,0,0,1-1,.15,3.14,3.14,0,0,1-.91-.14A2.22,2.22,0,0,1,43,7.89a2.14,2.14,0,0,1-.56-.7,2.19,2.19,0,0,1-.21-1A2.11,2.11,0,0,1,43,4.5a2.52,2.52,0,0,1,.79-.43,3.14,3.14,0,0,1,.91-.14,3,3,0,0,1,1,.15,2.16,2.16,0,0,1,.78.45,2.24,2.24,0,0,1,.53.72,2.42,2.42,0,0,1,.21,1ZM43.87,5.32a.89.89,0,0,0-.16.22,2.72,2.72,0,0,0-.11.25h2.13a1.89,1.89,0,0,0-.11-.25,1.12,1.12,0,0,0-.16-.22,1.41,1.41,0,0,0-.36-.27A1,1,0,0,0,44.66,5a1,1,0,0,0-.43.09,1.41,1.41,0,0,0-.36.27"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M50.32,5.15,50.09,5a.57.57,0,0,0-.26,0,1.19,1.19,0,0,0-.88.37,1.25,1.25,0,0,0-.18.24V8.39H47.41V4h1.36v.4a1.77,1.77,0,0,1,.58-.34,2.13,2.13,0,0,1,.74-.12,1.63,1.63,0,0,1,.49.06,3.29,3.29,0,0,1,.42.18Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M58.74,5.15,58.51,5a.57.57,0,0,0-.26,0,1.19,1.19,0,0,0-.88.37.91.91,0,0,0-.17.23V8.38H55.84V4H57.2v.4a1.77,1.77,0,0,1,.58-.34,2.13,2.13,0,0,1,.74-.12A1.63,1.63,0,0,1,59,4a3.29,3.29,0,0,1,.42.18Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M63.8,6.2a2.19,2.19,0,0,1-.21,1,2.14,2.14,0,0,1-.56.7,2.31,2.31,0,0,1-.79.42,3.06,3.06,0,0,1-1.83,0,2.22,2.22,0,0,1-.79-.42,2.14,2.14,0,0,1-.56-.7,2.19,2.19,0,0,1-.21-1,2.11,2.11,0,0,1,.77-1.7,2.52,2.52,0,0,1,.79-.43,3.06,3.06,0,0,1,1.83,0A2.34,2.34,0,0,1,63,4.5a2.11,2.11,0,0,1,.77,1.7m-1.69.87a1,1,0,0,0,.25-.4,1.49,1.49,0,0,0,.07-.47,1.51,1.51,0,0,0-.07-.48.94.94,0,0,0-.25-.4,1,1,0,0,0-.39-.26,1.29,1.29,0,0,0-.4-.08,1.18,1.18,0,0,0-.39.08,1.07,1.07,0,0,0-.64.65,1.45,1.45,0,0,0,0,1,.9.9,0,0,0,.25.39,1.21,1.21,0,0,0,.39.26,1,1,0,0,0,.39.08,1.23,1.23,0,0,0,.4-.08.82.82,0,0,0,.41-.24"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M65.78,3.93a2.45,2.45,0,0,1,.85.15,1.88,1.88,0,0,1,.67.39l-.74.89-.17-.11a1,1,0,0,0-.21-.1A.94.94,0,0,0,66,5.08l-.2,0a.52.52,0,0,0-.3.06.17.17,0,0,0-.08.13s0,.09.12.14l.29.14.53.23a2,2,0,0,1,.51.29.9.9,0,0,1,.34.33,1.09,1.09,0,0,1,.14.38,2.72,2.72,0,0,1,0,.42,1.13,1.13,0,0,1-.15.59,1.32,1.32,0,0,1-.38.4,1.48,1.48,0,0,1-.53.22,2.36,2.36,0,0,1-.58.07,2.54,2.54,0,0,1-1-.19A2.47,2.47,0,0,1,64,7.83l.58-.93a.7.7,0,0,0,.18.11l.28.11.34.08a2.09,2.09,0,0,0,.34,0,.21.21,0,0,0,.12,0l.09,0a.12.12,0,0,0,0-.11.16.16,0,0,0-.07-.14l-.27-.14-.53-.23A1.89,1.89,0,0,1,64.31,6a1.26,1.26,0,0,1-.25-.76,1.22,1.22,0,0,1,.15-.63,1.18,1.18,0,0,1,.4-.4A1.66,1.66,0,0,1,65.16,4a3.42,3.42,0,0,1,.62-.06"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M69.43,3.93a2.45,2.45,0,0,1,.85.15,1.88,1.88,0,0,1,.67.39l-.74.89L70,5.25a1,1,0,0,0-.21-.1.94.94,0,0,0-.22-.07l-.2,0a.52.52,0,0,0-.3.06.17.17,0,0,0-.08.13s0,.09.12.14l.29.14.56.23a2,2,0,0,1,.51.29,1.62,1.62,0,0,1,.31.34,1.09,1.09,0,0,1,.14.38,1.84,1.84,0,0,1,0,.41,1.13,1.13,0,0,1-.15.59,1.32,1.32,0,0,1-.38.4,1.48,1.48,0,0,1-.53.22,2.36,2.36,0,0,1-.58.07,2.54,2.54,0,0,1-1-.19,2.26,2.26,0,0,1-.71-.42l.58-.93a.7.7,0,0,0,.18.11l.28.11.34.08a2.09,2.09,0,0,0,.34,0,.21.21,0,0,0,.12,0l.09,0a.12.12,0,0,0,0-.11.16.16,0,0,0-.07-.14l-.27-.14-.53-.23A1.89,1.89,0,0,1,68,6a1.26,1.26,0,0,1-.25-.76,1.22,1.22,0,0,1,.15-.63,1.18,1.18,0,0,1,.4-.4A1.66,1.66,0,0,1,68.81,4a2.68,2.68,0,0,1,.62-.07"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
      <path
        d="M2.65,6.6A2.35,2.35,0,0,1,5,4.25a2.24,2.24,0,0,1,.52.06l2-2A4.86,4.86,0,0,0,5,1.59a5,5,0,0,0-3.53,8.57L3.36,8.28A2.33,2.33,0,0,1,2.65,6.6"
        transform="translate(0 -1.59)"
        fill="#009383"
      />
      <path d="M4.75,8.94h0" transform="translate(0 -1.59)" fill="#e30613" />
      <path
        d="M8.56,3.07h0A5,5,0,0,1,5,11.6H5A5,5,0,0,0,8.56,3.07"
        transform="translate(0 -1.59)"
        fill="#12284c"
      />
      <path
        d="M4.54,8.91h0l.2,0-.2,0"
        transform="translate(0 -1.59)"
        fill="#12284c"
      />
      <path d="M4.8,8.94h0" transform="translate(0 -1.59)" fill="#12284c" />
      <path
        d="M8.56,3.07h0L6.69,5,5,6.64H7.36A2.36,2.36,0,0,1,5,9H4.74l-.2,0L2.61,10.83,2.54,11A4.91,4.91,0,0,0,5,11.6,5,5,0,0,0,8.56,3.07"
        transform="translate(0 -1.59)"
        fill="#e30613"
      />
      <path
        d="M54,4v.47a1.94,1.94,0,0,0-.58-.4,1.71,1.71,0,0,0-.72-.14,2.46,2.46,0,0,0-.91.17,2,2,0,0,0-.7.46,2.11,2.11,0,0,0-.45.7,2.69,2.69,0,0,0-.16.92,2.42,2.42,0,0,0,.16.91,2,2,0,0,0,.44.71,1.9,1.9,0,0,0,.7.46,2.42,2.42,0,0,0,.91.17,2.11,2.11,0,0,0,.73-.12A1.77,1.77,0,0,0,54,8a.81.81,0,0,1-.06.31,1.12,1.12,0,0,1-.34.42l-.09.06a1.57,1.57,0,0,1-1.09.11l-.92.92c1.66.76,2.66.24,3.17-.26l0,0,0,0,.09-.1.06-.07A.94.94,0,0,0,55,9.21l0,0,.1-.16h0l0-.06h0l.07-.13A2.06,2.06,0,0,0,55.37,8V4Zm0,2.8a1.18,1.18,0,0,1-.17.24,1.21,1.21,0,0,1-.87.37,1,1,0,0,1-.44-.09,1.41,1.41,0,0,1-.36-.27,1,1,0,0,1-.25-.4,1.45,1.45,0,0,1-.07-.47,1.51,1.51,0,0,1,.07-.48.94.94,0,0,1,.25-.4A1.69,1.69,0,0,1,52.52,5,1,1,0,0,1,53,5a1.27,1.27,0,0,1,.47.09,1.08,1.08,0,0,1,.57.51Z"
        transform="translate(0 -1.59)"
        fill="#1b2a4a"
      />
    </svg>
  )
}
