/* eslint-disable unicorn/prefer-dom-node-text-content */
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

function animateFrom(element, direction = 1) {
  let x
  let y

  if (element.classList.contains('reveal_from_left')) {
    x = -200 * direction
    y = 0
  } else if (element.classList.contains('reveal_from_right')) {
    x = 200 * direction
    y = 0
  } else if (element.classList.contains('reveal_from_top')) {
    x = 0
    y = -100 * direction
  } else if (element.classList.contains('reveal_from_bottom')) {
    x = 0
    y = 100 * direction
  }

  // element.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
  // element.style.opacity = '0'

  gsap.fromTo(
    element,
    { x, y, autoAlpha: 0 },
    {
      duration: 2,
      x: 0,
      y: 0,
      autoAlpha: 1,

      ease: 'expo',
      overwrite: 'auto'
    }
  )
}

function hide(element) {
  gsap.set(element, { autoAlpha: 0 })
}

export const addReveal = () => {
  gsap.registerPlugin(ScrollTrigger)

  // eslint-disable-next-line no-restricted-syntax
  for (const element of gsap.utils.toArray('.reveal')) {
    hide(element) // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: element,
      onEnter() {
        animateFrom(element)
      },
      onEnterBack() {
        animateFrom(element, -1)
      },
      onLeave() {
        hide(element)
      } // assure that the element is hidden when scrolled into view
    })
  }
}

export default addReveal
