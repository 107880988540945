// import * as THREE from 'three'
import React, { useState, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls, Html, useProgress, useTexture } from '@react-three/drei'
// import { TextureLoader } from 'three/src/loaders/TextureLoader'
// import Model from './components/Model'

export default function Test() {
  return (
    <div className="w-screen h-screen">
      <Canvas>
        <Suspense fallback={<Loader />}>
          <MyRotatingBox />

          {/* eslint-disable-next-line unicorn/numeric-separators-style */}
          <ambientLight args={[0xff0000]} intensity={0.1} />

          <directionalLight position={[0, 0, 5]} intensity={0.5} />

          <Scene />
          {/* <Model /> */}

          <OrbitControls />

          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
    </div>
  )
}

/* <mesh
scale={active ? 1.5 : 1}
onClick={() => setActive(!active)}
ref={myMesh}
onContextMenu={e => console.log('context menu')}
onDoubleClick={e => console.log('double click')}
onWheel={e => console.log('wheel spins')}
onPointerUp={e => console.log('up')}
onPointerDown={e => console.log('down')}
onPointerOver={e => console.log('over')}
onPointerOut={e => console.log('out')}
onPointerEnter={e => console.log('enter')}
onPointerLeave={e => console.log('leave')}
onPointerMove={e => console.log('move')}
onPointerMissed={() => console.log('missed')}
onUpdate={self => console.log('props have been updated')}
></mesh> */

const MyRotatingBox = () => {
  const myMesh = React.useRef()
  const [active, setActive] = useState(false)

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime()
    myMesh.current.rotation.x = a
  })

  return (
    <mesh
      scale={active ? 1.5 : 1}
      onClick={() => setActive(!active)}
      ref={myMesh}
    >
      <boxBufferGeometry />
      <meshPhongMaterial color="royalblue" />
    </mesh>
  )
}

// function Object1() {
//   const gltf = useLoader(GLTFLoader, '/Poimandres.gltf')
//   return <primitive object={gltf.scene} scale={0.4} />
// }

const Loader = () => {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

function Scene() {
  // const [colorMap, displacementMap, normalMap, roughnessMap, aoMap] =
  //   useTexture([
  //     '/PavingStones092_1K-JPG/PavingStones092_1K_Color.jpg',
  //     '/PavingStones092_1K-JPG/PavingStones092_1K_Displacement.jpg',
  //     '/PavingStones092_1K-JPG/PavingStones092_1K_NormalDX.jpg',
  //     '/PavingStones092_1K-JPG/PavingStones092_1K_Roughness.jpg',
  //     '/PavingStones092_1K-JPG/PavingStones092_1K_AmbientOcclusion.jpg'
  //   ])
  const texture = useTexture({
    map: '/PavingStones092_1K-JPG/PavingStones092_1K_Color.jpg',
    displacementMap:
      '/PavingStones092_1K-JPG/PavingStones092_1K_Displacement.jpg',
    normalMap: '/PavingStones092_1K-JPG/PavingStones092_1K_NormalDX.jpg',
    roughnessMap: '/PavingStones092_1K-JPG/PavingStones092_1K_Roughness.jpg',
    aoMap: '/PavingStones092_1K-JPG/PavingStones092_1K_AmbientOcclusion.jpg'
  })

  return (
    <>
      <ambientLight intensity={0.2} />
      <directionalLight />
      <mesh>
        <sphereGeometry args={[1, 32, 32]} />
        <meshStandardMaterial
          displacementScale={0.2}
          {...texture}
          // map={colorMap}
          // displacementMap={displacementMap}
          // normalMap={normalMap}
          // roughnessMap={roughnessMap}
          // aoMap={aoMap}
        />
      </mesh>
    </>
  )
}
