import * as THREE from 'three'
import { Suspense, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Reflector, Text, useTexture, useGLTF } from '@react-three/drei'
import Overlay from './components/Overlay'

function Carla(props) {
  const { scene } = useGLTF('/carla-draco.glb')
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <primitive object={scene} {...props} />
}

// eslint-disable-next-line react/prop-types
function VideoText({ clicked, ...props }) {
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: 'CaptainMarvel-OpeningLogoHD_StanLeeTribute-KYZ9obDJJtU.f137.mp4',
      crossOrigin: 'Anonymous',
      loop: true
    })
  )
  // eslint-disable-next-line no-void
  useEffect(() => void (clicked && video.play()), [video, clicked])
  return (
    <Text
      font="/Inter-Bold.woff"
      fontSize={2}
      letterSpacing={-0.06}
      maxWidth={2}
      lineHeight={0.7}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      2AeS Group
      <meshBasicMaterial toneMapped={false}>
        <videoTexture
          attach="map"
          args={[video]}
          encoding={THREE.sRGBEncoding}
        />
      </meshBasicMaterial>
    </Text>
  )
}

function Ground() {
  const [floor, normal] = useTexture([
    '/SurfaceImperfections003_1K_var1.jpg',
    '/SurfaceImperfections003_1K_Normal.jpg'
  ])
  return (
    <Reflector
      blur={[400, 100]}
      resolution={512}
      args={[14, 10]}
      mirror={0.5}
      mixBlur={6}
      mixStrength={1.5}
      rotation={[-Math.PI / 2, 0, Math.PI / 2]}
    >
      {(Material, props) => (
        <Material
          color="#a0a0a0"
          metalness={0.4}
          roughnessMap={floor}
          normalMap={normal}
          normalScale={[2, 2]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
    </Reflector>
  )
}

export default function Home1() {
  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)
  const store = { clicked, setClicked, ready, setReady }
  return (
    <>
      <Canvas
        concurrent
        gl={{ alpha: false }}
        pixelRatio={[1, 1.5]}
        camera={{ position: [0, 3, 100], fov: 15 }}
      >
        <color attach="background" args={['black']} />
        <fog attach="fog" args={['black', 15, 20]} />
        {/* eslint-disable-next-line unicorn/no-null */}
        <Suspense fallback={null}>
          <group position={[0, -1, 0]}>
            <Carla
              rotation={[0, Math.PI - 0.4, 0]}
              position={[-1.2, 0, 0.6]}
              scale={[0.26, 0.26, 0.26]}
            />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <VideoText {...store} position={[0, 1.9, -3]} />
            <Ground />
          </group>
          <ambientLight intensity={0.5} />
          <spotLight position={[0, 10, 0]} intensity={0.3} />
          <directionalLight position={[-20, 0, -10]} intensity={0.7} />
          <Intro start={ready && clicked} set={setReady} />
        </Suspense>
      </Canvas>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Overlay {...store} />
    </>
  )
}

function Intro({ start, set }) {
  const [vec] = useState(() => new THREE.Vector3())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTimeout(() => set(true), 500), [])
  return useFrame(state => {
    if (start) {
      state.camera.position.lerp(
        vec.set(state.mouse.x * -10, 3 + state.mouse.y * -4, 14),
        0.05
      )
      state.camera.lookAt(0, 0, 0)
    }
  })
}
