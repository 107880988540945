/* eslint-disable no-unused-vars */
export default function LogoEsprinet({ ...props }) {
  return (
    <svg
      id="Livello_1"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.18 57.07"
      {...props}
    >
      <path
        d="M52.15,27.35c-.17-1.34-1.21-2-2.67-2a2.77,2.77,0,0,0-2.88,2Zm-5.59,2.1c.16,1.28,1.27,2.2,3.07,2.2a4.25,4.25,0,0,0,2.75-1L54,32.29A6.32,6.32,0,0,1,49.58,34c-3.55,0-5.65-2.19-5.65-5.49a5.15,5.15,0,0,1,5.47-5.38c3.44,0,5.59,2.13,5.19,6.36Z"
        fill="#005091"
      />
      <path
        d="M62.76,26.13a3.51,3.51,0,0,0-2.55-.91c-1.21,0-1.88.37-1.88,1s.61,1,1.92,1.13c1.94.12,4.41.56,4.41,3.3,0,1.81-1.49,3.38-4.43,3.38a6,6,0,0,1-4.76-1.84l1.25-1.82a5.61,5.61,0,0,0,3.55,1.44c1,0,1.86-.48,1.86-1.23s-.58-1-2.05-1.08c-1.94-.15-4.26-.86-4.26-3.2s2.47-3.21,4.35-3.21a5.54,5.54,0,0,1,4,1.35Z"
        fill="#005091"
      />
      <path
        d="M74.38,28.56a2.92,2.92,0,1,0-5.84,0,2.92,2.92,0,0,0,5.84,0M65.76,38V23.4h2.38l.17,1.42a4.08,4.08,0,0,1,3.34-1.65,5.15,5.15,0,0,1,5.28,5.39c0,3-1.9,5.38-5.2,5.38a4.14,4.14,0,0,1-3.42-1.46V38Z"
        fill="#005091"
      />
      <path
        d="M80.65,23.4l.18,1.19a3.05,3.05,0,0,1,2.91-1.46,3.94,3.94,0,0,1,2.65,1l-1.15,2.21a2.53,2.53,0,0,0-1.84-.67,2.38,2.38,0,0,0-2.57,2.61v5.43H78.29V23.4Z"
        fill="#005091"
      />
      <path
        d="M86.82,33.69h2.55V23.36H86.82ZM89.6,20.5a1.5,1.5,0,0,1-3,0,1.5,1.5,0,0,1,3,0"
        fill="#005091"
      />
      <path
        d="M98.73,33.69V28.31a2.46,2.46,0,0,0-2.49-2.76,2.71,2.71,0,0,0-2.63,2.88v5.26H91.08V23.38h2.28l.17,1.4a4.6,4.6,0,0,1,3.38-1.55c2.42,0,4.36,1.82,4.36,5.05v5.41Z"
        fill="#005091"
      />
      <path
        d="M110.71,27.35c-.17-1.34-1.21-2-2.67-2a2.78,2.78,0,0,0-2.89,2Zm-5.6,2.1c.17,1.28,1.28,2.2,3.07,2.2a4.3,4.3,0,0,0,2.76-1l1.63,1.6A6.32,6.32,0,0,1,108.14,34c-3.55,0-5.66-2.19-5.66-5.49A5.15,5.15,0,0,1,108,23.09c3.45,0,5.6,2.13,5.2,6.36Z"
        fill="#005091"
      />
      <path
        d="M117.74,20.5v2.92h2.84v2.19h-2.86v4.45a1.28,1.28,0,0,0,1.34,1.46,2.91,2.91,0,0,0,1.23-.31l.71,2.17a5.73,5.73,0,0,1-2.11.44c-2.23.08-3.69-1.19-3.69-3.76V25.61h-1.93V23.42h1.93V20.77Z"
        fill="#005091"
      />
      <path
        d="M36.18,28.54A12.5,12.5,0,1,1,23.68,16a12.5,12.5,0,0,1,12.5,12.5"
        fill="#005091"
      />
      <path
        d="M24.05,33.91a6.26,6.26,0,0,0,4.37-1.65l-1.61-1.59a4.18,4.18,0,0,1-2.72.95c-1.77,0-2.87-.91-3-2.17H18.53c.38,2.71,2.37,4.46,5.52,4.46"
        fill="#fff"
      />
      <path
        d="M21.1,27.37h0a2.73,2.73,0,0,1,2.84-2c1.44,0,2.47.66,2.64,2H21.1Zm7.86,0c-.4-2.8-2.32-4.2-5.1-4.2a5,5,0,0,0-5.3,4.2h-4v2.08H29a8.4,8.4,0,0,0,0-2.08"
        fill="#fff"
      />
      <rect x="13.42" y="27.37" width="0.64" height="2.09" fill="#fff" />
      <rect x="12.3" y="27.37" width="0.47" height="2.09" fill="#fff" />
      <rect x="11.18" y="27.37" width="0.31" height="2.09" fill="#fff" />
      <path
        d="M21.06,29.45H18.53a1.72,1.72,0,0,0,0,.24,2,2,0,0,1,0-.24Zm.05.29a2.47,2.47,0,0,0,.1.29h0a2.47,2.47,0,0,1-.1-.29"
        fill="#005091"
      />
      <path
        d="M21.06,29.45H18.53a1.72,1.72,0,0,0,0,.24,3.4,3.4,0,0,0,.07.34h2.57a2.47,2.47,0,0,1-.1-.29,2.5,2.5,0,0,1-.05-.29"
        fill="#abb2c4"
      />
      <text
        transform="translate(120.73 21.27)"
        fontSize="5"
        fill="#005091"
        fontFamily="NexaLight"
      >
        ®
      </text>
    </svg>
  )
}
