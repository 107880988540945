import { Routes, Route } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import Plausible from 'plausible-tracker'
import Home from './Home'
import Comelavoriamo from './Comelavoriamo'
import Home1 from './Home1'
import Home2 from './Home2'
import MacInception from './MacInception'
import Mac from './Mac'
import HeroPage from './HeroPage'
import './styles.scss'
import Policies from './Privacy'
import Cookiepolicy from './Cookiepolicy'
import Infolegge from './Infolegge'
import Legal from './Legal'
import Test from './Test'
import Test2 from './Test2'
import Test3 from './Test3'
import Test4 from './Test4'
import Test5 from './Test5'
import useGaTracker from './lib/GAnal'

// ReactGA.initialize('UA-221596549-1')

const NoMatchPage = () => (
  <div>
    <h3 className="text-center text-6xl">404 - Nessun risultato</h3>
  </div>
)

// eslint-disable-next-line react/prop-types, no-unused-vars
const App = ({ cookies }) => {
  useGaTracker()
  // const navigate = useNavigate()
  // const viewsCount = cookies.get('viewsCount') ?? 0

  const plausible = Plausible({
    domain: '2aes-group.it',
    apiHost: '/aloha'
  })

  const { enableAutoPageviews } = plausible

  // This tracks the current page view and all future ones as well
  enableAutoPageviews()

  const { enableAutoOutboundTracking } = plausible

  // Track all existing and future outbound links
  enableAutoOutboundTracking()

  // React.useEffect(() => {
  //   if (+viewsCount < 4) {
  //     cookies.set('viewsCount', viewsCount + 1)
  //     navigate('/home3')
  //   }
  // }, [])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="comelavoriamo" element={<Comelavoriamo />} />
      <Route path="home1" element={<Home1 />} />
      <Route path="home2" element={<Home2 />} />
      <Route path="home3" element={<MacInception />} />
      <Route path="home4" element={<Mac />} />
      <Route path="heropage" element={<HeroPage />} />
      <Route path="privacy-policy" element={<Policies />} />
      <Route path="cookie-policy" element={<Cookiepolicy />} />
      <Route path="legali" element={<Legal />} />
      <Route path="infolegal" element={<Infolegge />} />
      <Route path="test" element={<Test />} />
      <Route path="test2" element={<Test2 />} />
      <Route path="test3" element={<Test3 />} />
      <Route path="test4" element={<Test4 />} />
      <Route path="test5" element={<Test5 />} />
      {/* TODO: personalizzare la 404 */}
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  )
}

export default withCookies(App)
