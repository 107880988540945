import * as THREE from 'three'
import { useRef, Suspense } from 'react'
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import {
  Reflector,
  CameraShake,
  OrbitControls,
  useTexture,
  Text
} from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
// import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

// function Triangle({ color, ...props }) {
//   const ref = useRef()
//   const [r] = useState(() => Math.random() * 10000)
//   useFrame(
//     _ =>
//       (ref.current.position.y = -1.75 + Math.sin(_.clock.elapsedTime + r) / 10)
//   )
//   const { paths: [path] } = useLoader(SVGLoader, '/triangle.svg') // prettier-ignore
//   const geom = useMemo(
//     () =>
//       SVGLoader.pointsToStroke(
//         path.subPaths[0].getPoints(),
//         path.userData.style
//       ),
//     []
//   )
//   return (
//     <group ref={ref}>
//       <mesh geometry={geom} {...props}>
//         <meshBasicMaterial color={color} toneMapped={false} />
//       </mesh>
//     </group>
//   )
// }

// function TextSVG({ color, svgPath, ...props }) {
//   const ref = useRef()
//   const [r] = useState(() => Math.random() * 10000)
//   useFrame(
//     _ =>
//       (ref.current.position.y = -1.75 + Math.sin(_.clock.elapsedTime + r) / 10)
//   )
//   const { paths: [path] } = useLoader(SVGLoader, svgPath) // prettier-ignore
//   const geom = useMemo(
//     () =>
//       SVGLoader.pointsToStroke(
//         path.subPaths[0].getPoints(),
//         path.userData.style
//       ),
//     []
//   )
//   return (
//     <group ref={ref}>
//       <mesh geometry={geom} {...props}>
//         <meshBasicMaterial color={color} toneMapped={false} />
//       </mesh>
//     </group>
//   )
// }

// eslint-disable-next-line react/prop-types
function TextDOM({ color = '#1f3633', textContent, ...props }) {
  return (
    <Text
      font="/Inter-Bold.woff"
      fontSize={2}
      letterSpacing={-0.06}
      maxWidth={2}
      lineHeight={0.7}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {textContent}
      <meshBasicMaterial toneMapped={false} />
    </Text>
  )
}

function Rig({ children }) {
  const reference = useRef()
  const vec = new THREE.Vector3()
  const { camera, mouse } = useThree()
  useFrame(() => {
    camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
    reference.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
    reference.current.rotation.y = THREE.MathUtils.lerp(
      reference.current.rotation.y,
      (-mouse.x * Math.PI) / 20,
      0.1
    )
  })
  return <group ref={reference}>{children}</group>
}

function Ground(props) {
  const [floor, normal] = useTexture([
    '/SurfaceImperfections003_1K_var1.jpg',
    '/SurfaceImperfections003_1K_Normal.jpg'
  ])
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {/* eslint-disable-next-line no-shadow */}
      {(Material, props) => (
        <Material
          color="#f0f0f0"
          metalness={0}
          roughnessMap={floor}
          normalMap={normal}
          normalScale={[2, 2]}
          {...props}
        />
      )}
    </Reflector>
  )
}

export default function Home2() {
  return (
    <Canvas dpr={[1, 1.5]} camera={{ position: [0, 0, 15] }}>
      <color attach="background" args={['black']} />
      <ambientLight />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        enableRotate={false}
      />
      {/* eslint-disable-next-line unicorn/no-null */}
      <Suspense fallback={null}>
        <Rig>
          {/* <Triangle
            color="#ff2060"
            scale={0.009}
            rotation={[0, 0, Math.PI / 3]}
          />
          <Triangle
            color="cyan"
            scale={0.009}
            position={[2, 0, -2]}
            rotation={[0, 0, Math.PI / 3]}
          />
          <Triangle
            color="orange"
            scale={0.009}
            position={[-2, 0, -2]}
            rotation={[0, 0, Math.PI / 3]}
          />
          <Triangle
            color="white"
            scale={0.009}
            position={[0, 2, -10]}
            rotation={[0, 0, Math.PI / 3]}
          /> */}
          {/* <TextSVG
            color="white"
            scale={0.009}
            position={[-2, 2, -1]}
            rotation={[0, 0, Math.PI]}
            svgPath="/2aes/2.svg"
          />
          <TextSVG
            color="white"
            scale={0.009}
            position={[-1, 2, -1]}
            rotation={[0, 0, Math.PI]}
            svgPath="/2aes/a.svg"
          />
          <TextSVG
            color="white"
            scale={0.009}
            position={[0, 2, -1]}
            rotation={[0, 0, Math.PI]}
            svgPath="/2aes/e.svg"
          />
          <TextSVG
            color="white"
            scale={0.009}
            position={[1, 2, -1]}
            rotation={[0, 0, Math.PI]}
            svgPath="/2aes/s.svg"
          /> */}
          <TextDOM textContent="2" position={[-2, 0.3, -2]} />
          <TextDOM textContent="A" position={[-0.5, 0.3, -2]} />
          <TextDOM textContent="e" position={[1, 0, -3]} />
          <TextDOM textContent="S" position={[2.5, 0.3, -2]} />
          <TextDOM textContent="Group" position={[0.7, 2, -10]} />
          {/* <Text
            font="/Inter-Bold.woff"
            fontSize={2}
            letterSpacing={-0.06}
            maxWidth={2}
            lineHeight={0.7}
            position={[-1, 2, -2]}
            color="#30524e"
          >
            2
            <meshBasicMaterial toneMapped={false} />
          </Text>
          <Text
            font="/Inter-Bold.woff"
            fontSize={2}
            letterSpacing={-0.06}
            maxWidth={2}
            lineHeight={0.7}
            position={[0, 1, -1]}
          >
            A
            <meshBasicMaterial toneMapped={false} />
          </Text>
          <Text
            font="/Inter-Bold.woff"
            fontSize={2}
            letterSpacing={-0.06}
            maxWidth={2}
            lineHeight={0.7}
            position={[1, 2, -2]}
          >
            e
            <meshBasicMaterial toneMapped={false} />
          </Text>
          <Text
            font="/Inter-Bold.woff"
            fontSize={2}
            letterSpacing={-0.06}
            maxWidth={2}
            lineHeight={0.7}
            position={[2, 2, -1]}
          >
            S
            <meshBasicMaterial toneMapped={false} />
          </Text> */}
          <Ground
            mirror={1}
            blur={[500, 100]}
            mixBlur={12}
            mixStrength={1.5}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            position-y={-0.8}
          />
        </Rig>
        <EffectComposer multisampling={8}>
          <Bloom
            kernelSize={3}
            luminanceThreshold={0}
            luminanceSmoothing={0.4}
            intensity={0.6}
          />
          <Bloom
            kernelSize={KernelSize.HUGE}
            luminanceThreshold={0}
            luminanceSmoothing={0}
            intensity={0.5}
          />
        </EffectComposer>
      </Suspense>
      <CameraShake
        yawFrequency={0.2}
        pitchFrequency={0.2}
        rollFrequency={0.2}
      />
    </Canvas>
  )
}
