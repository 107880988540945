import { Suspense, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Image, Placeholder, Transformation } from 'cloudinary-react'
import { useNavigate } from 'react-router-dom'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import { addReveal } from './lib/Reveal'
import Logo from './components/Logo'
import { useArrayReference, useGsapSelector } from './lib/GsapHelpers'

export default function Home() {
  const navigate = useNavigate()

  const [references, setReference] = useArrayReference()
  const [q, homePageReference] = useGsapSelector()
  useEffect(() => {
    addReveal()
  }, [])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.set(q('.logo'), {
      // perspective: 800,
      // transformStyle: 'preserve-3d',
      // backfaceVisibility: 'hidden',
      transformOrigin: 'left'
    })

    gsap.to(
      q('.logo'),

      {
        height: '3rem',
        ease: 'none',
        scrollTrigger: {
          trigger: q('.first-page'),
          start: 'top middle',
          end: 'bottom top',
          // markers: true,
          scrub: true
        }
      }
    )

    gsap.to(
      q('.logo'),

      {
        marginLeft: '100%',
        x: '-6rem',

        // ease: 'expo.out',
        scrollTrigger: {
          trigger: q('.scrollSection'),
          start: 'top top',
          end: 'bottom bottom',
          // markers: true,
          scrub: true
        }
      }
    )
  }, [homePageReference, q])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    references?.current?.map(currentReference =>
      gsap.fromTo(
        currentReference.querySelectorAll('.pSection'),
        { yPercent: 10 },
        {
          yPercent: -10,
          ease: 'none',
          scrollTrigger: {
            trigger: currentReference,
            start: 'top bottom',
            end: 'bottom top',
            // markers: true,
            scrub: true
          }
        }
      )
    )
  }, [references])

  return (
    <div
      className="relative w-full h-full bg-brand-back"
      ref={homePageReference}
    >
      <article className="relative w-full h-full ">
        <div
          className="fixed inset-x-0 top-0 h-24 z-50 logo"
          onClick={() => navigate('/#servizi')}
          onKeyDown={() => navigate('/#servizi')}
          role="button"
          tabIndex={0}
        >
          <Logo className="h-full w-24" color="#30524e" />
        </div>
        {/* prima sezione full screen con video */}
        <section className="relative flex h-full overflow-hidden ">
          <div className="relative flex flex-col flex-1 bottom-0 justify-end z-10 py-4 px-5 max-w-3xl lg:ml-24">
            <div className="text-brand text-center sm:text-left">
              {/* zona testo */}
              <h1 className="text-5xl sm:text-6xl md:text-7xl xl:text-8xl leading-10 px-1">
                La tua immagine è importante
              </h1>
              <p className="px-1 pt-4 pb-1 text-sm sm:text-base md:text-xl xl:text-2xl  sm:mx-auto lg:mx-0">
                Come lavoriamo e cosa possiamo fare per te. SEO/SEM, Branding,
                Siti web e tanto altro
              </p>
            </div>
          </div>
          <div className="absolute inset-0 object-center object-cover flex items-center justify-center h-screen overflow-hidden">
            <Image
              alt=" "
              publicId="obiettivo_nawdbz.jpg"
              cloudName="group-2aes"
              loading="lazy"
              className="object-cover h-full 2xl:h-auto"
              responsive
              width="auto"
            >
              <Transformation quality="auto" fetchFormat="auto" />
              <Placeholder />
            </Image>
          </div>
        </section>
        <div className="relative scrollSection">
          {/* Sezione parallasse */}
          <section className="relative bg-brand-hot" ref={setReference}>
            <div
              className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center
           bg-branddark rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28"
            >
              <div className="xl:order-2 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="social_kigoeb.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-1 px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-whiteBrand text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    Gestione coordinata della tua presenza online
                  </h1>
                  <h3 className="font-Open font-bold  text-whiteBrand text-xl md:text-2xl ">
                    Tutto in uno
                  </h3>
                </div>
                <p className=" text-whiteBrand text-base text-justify sm:text-lg md:text-xl ">
                  Il mondo digitale è splendido, come lo sono i tuoi prodotti e
                  la tua attività. 2AeS Group non ha paura delle sfide che gli
                  proporrai. Oggi, la presenza e le vendite online hanno il
                  mercato più grande sempre, e quindi per i nostri clienti
                  abbiamo pensato alle soluzioni più grandi di sempre. Da noi,
                  come tuo partner tecnologico, ottieni tutto il supporto di cui
                  hai bisogno, in tutte le fasi del percorso. Così potrai
                  occuparti della cosa più importante: il tuo lavoro di
                  artigiano. Quello che a noi sta a cuore. Per raggiungere dei
                  risultati concreti e per dare alla tua attività la giusta
                  visibilità e credibilità sul web è necessario agire in maniera
                  coordinata.
                </p>
              </div>
              <div className="xl:order-3 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-l-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="where-to-go_wyplsa.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-4 px-6 py-3 my-4 lg:px-10 2xl:px-20 lg:py-5 lg:my-0">
                <h3 className="font-Open font-bold  text-whiteBrand text-xl md:text-2xl pb-4">
                  Non perderti, affidati a noi
                </h3>
                <p className="text-base text-whiteBrand text-justify sm:text-lg md:text-xl">
                  Affidando a noi la creazione del tuo sito web, i contenuti
                  video fotografici e testuali, la gestione dei tuoi profili
                  social, la tua pagina Google My business e le eventuali
                  pubblicità avrai la possibilità di raggiungere quella coerenza
                  oggi necessaria per acquisire e soddisfare nuova clientela e
                  consolidare quella preesistente. Non ti diamo consigli su come
                  fare le cose ma le facciamo direttamente noi per te!
                  Continuando a leggere potrai capire meglio cosa facciamo per
                  aiutarti a migliorare e consolidare la tua presenza online
                </p>
              </div>
            </div>
          </section>
          {/*  */}
          <section
            className="relative bg-brand shadow-inner shadow-shadowBrand"
            ref={setReference}
          >
            <div
              className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center
          bg-brand-hot rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28"
            >
              <div className="xl:order-2 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="seo_kexypj.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-1 px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-branddark text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    SEO
                  </h1>
                  <h3 className="font-Open font-bold  text-branddark text-xl md:text-2xl ">
                    Ottimizzazione per i motori di ricerca
                  </h3>
                </div>
                <p className=" text-branddark text-base text-justify sm:text-lg md:text-xl ">
                  Ottimizzare il tuo sito web per i motori di ricerca e i social
                  (SEO) come Google e Facebook ti aiuta a comparire nei
                  risultati per sfruttare un’opportunità di fondamentale
                  importanza: rivolgerti agli utenti che sono interessati al tuo
                  prodotto o servizio proprio in quel momento. Farti trovare sui
                  motori di ricerca e sui social migliora la tua visibilità
                  online, anche a livello locale, aumentando la possibilità di
                  raggiungere nuovi clienti interessati! Ma veniamo a noi: i
                  siti web e i pacchetti marketing che offriamo sono innovativi,
                  fatti su misura per te e ruotano attorno al SEO per migliorare
                  la posizione organica della tua attività nei risultati di
                  ricerca.
                </p>
              </div>
              <div className="xl:order-3 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-l-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=" "
                    publicId="g-search.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-4 px-6 py-3 my-4 lg:px-10 2xl:px-20 lg:py-5 lg:my-0">
                <h3 className="font-Open font-bold  text-branddark text-xl md:text-2xl pb-4">
                  Ricerche organiche
                </h3>
                <p className="text-base text-branddark text-justify sm:text-lg md:text-xl">
                  Ottimizzando le ricerche organiche per la tua attività le
                  persone avranno maggiori probabilità di incontrarti quando
                  effettuano ricerche online. Per ricerche organiche si intende
                  che non pagherai il motore di ricerca per comparire nei
                  risultati, succederà e basta. Ma migliore sarà
                  l’ottimizzazione tecnica, grafica e la pertinenza del tuo sito
                  web, del tuo profilo Google My Business e delle tue pagine
                  Social e maggiori saranno le possibilità che un potenziale
                  cliente interessato trovi proprio te! Non perdere questa
                  magnifica occasione, ti aiutiamo a coglierla!
                </p>
              </div>
            </div>
          </section>

          <section className="relative bg-brand-hot" ref={setReference}>
            <div className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center bg-branddark rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28">
              <div className="xl:order-2 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="SEM_krg9ss.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-1 px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-whiteBrand text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    SEM e marketing digitale
                  </h1>
                  <h3 className="font-Open font-bold  text-whiteBrand text-xl md:text-2xl ">
                    Pubblicità a pagamento sui motori di ricerca e sui social
                  </h3>
                </div>
                <p className=" text-whiteBrand text-base text-justify sm:text-lg md:text-xl ">
                  Un altro strumento molto efficace per migliorare la presenza
                  online della tua attività si riassume nella parola SEM
                  (marketing a pagamento per i motori di ricerca). In breve, si
                  tratta di una serie di strumenti messi a disposizione da
                  Google, Facebook ecc. per creare annunci da mostrare su
                  internet. È possibile creare annunci di vario tipo e mostrarli
                  in maniera mirata a persone che cercano su Google parole e
                  frasi rilevanti per la tua attività oppure a potenziali
                  clienti che hanno particolari interessi sui Social network.
                  Queste tipologie di annunci sono molto efficaci e hanno anche
                  un vantaggio economico: una volta impostati, pagherai il
                  motore di ricerca o il social solo quando un potenziale
                  cliente cliccherà o vedrà fisicamente l’annuncio.
                </p>
              </div>
              <div className="xl:order-3 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-l-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="acquisti_zc6i9i.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-4 px-6 py-3 my-4 lg:px-10 2xl:px-20 lg:py-5 lg:my-0">
                <h3 className="font-Open font-bold  text-whiteBrand text-xl md:text-2xl pb-4">
                  Campagne contemporanee su più canali
                </h3>
                <p className="text-base text-whiteBrand text-justify sm:text-lg md:text-xl">
                  Avviare contemporaneamente campagne pubblicitarie su Google e
                  Facebook/Instagram in maniera coordinata ti premetterà di
                  raggiungere sia persone che già cercano prodotti e servizi
                  simili ai tuoi e sia persone che potrebbero essere interessate
                  ma che ancora non li cercano attivamente ! Come possiamo
                  aiutarti? Offriamo servizi per creare annunci specifici e
                  coordinati su Google, Facebook e Instagram. Studiamo il tuo
                  settore e i tuoi potenziali clienti; decidiamo con te quali
                  obiettivi vuoi raggiungere e poi strutturiamo le campagne
                  pubblicitarie necessarie per raggiungerli!
                </p>
              </div>
            </div>
          </section>
          <section
            className="relative bg-brand shadow-inner shadow-shadowBrand"
            ref={setReference}
          >
            <div className="max-w-2xl xl:max-w-none pSection grid grid-row-2 xl:grid-cols-2 items-center justify-items-center bg-brand-hot rounded-sm shadow-md shadow-shadowBrand mx-5 sm:mx-10 md:mx-auto lg:mx-18 xl:mx-28">
              <div className="xl:order-2 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-r-sm">
                <div className="aspect-w-4 aspect-h-4 ">
                  <Image
                    alt=""
                    publicId="white_mac.jpg"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-1 px-6 py-3 md:lg:px-8 lg:px-10 lg:py-5 2xl:px-20">
                <div className="overflow-hidden pb-4">
                  <h1 className="font-raleway font-normal text-branddark text-3xl sm:text-4xl md:text-5xl pb-2 reveal reveal_from_left">
                    Sito web innovativo
                  </h1>
                  <h3 className="font-Open font-bold  text-branddark text-xl md:text-2xl ">
                    Veloce ed ottimizzato per i telefoni
                  </h3>
                </div>
                <p className=" text-branddark text-base text-justify sm:text-lg md:text-xl ">
                  La nostra proposta per il sito web è incentrata
                  sull&apos;utilizzo di strumenti innovativi e moderni, ovvero
                  infrastrutture e metodi di programmazione che rendono il sito
                  veloce da caricare, ottimizzato per i telefoni e conforme alle
                  linee guida SEO sopra citate. Personalizziamo fino all’ultima
                  riga di codice sulle tue esigenze. Questo permette di
                  realizzare un sito web ottimizzato per i motori di ricerca e
                  perfetto per sostenere una delle nostre soluzioni e-commerce o
                  eventuali campagne ads.
                </p>
              </div>
              <div className="xl:order-3 w-full shadow-sm shadow-shadowBrand overflow-hidden xl:rounded-l-sm">
                <div className="aspect-w-4 aspect-h-4">
                  <Image
                    alt="mole antonelliana"
                    publicId="white-mac-sito_t3bxm9.png"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="xl:order-4 px-6 py-3 my-4 lg:px-10 2xl:px-20 lg:py-5 lg:my-0">
                <h3 className="font-Open font-bold  text-branddark text-xl md:text-2xl pb-4">
                  La grafica e i contenuti
                </h3>
                <p className="text-base text-branddark text-justify sm:text-lg md:text-xl">
                  La progettazione grafica viene realizzata tenendo conto delle
                  tue esigenze, analizzando i grandi competitor del settore di
                  riferimento, per essere allineati a quello che offre il
                  mercato e rendere la tua immagine online più bella e
                  accattivante possibile! I nostri collaboratori, esperti in
                  fotografia, video editing, branding e scrittura testi,
                  creeranno i contenuti necessari per il sito web, per i social
                  e per le pubblicità! In seguito, i nostri tecnici informatici
                  inseriranno tutti i contenuti nei punti giusti e si
                  prenderanno cura della tua immagine.
                </p>
              </div>
            </div>
          </section>
          <section className="relative bg-brand-hot shadow-inner p-8">
            <div
              ref={setReference}
              className="max-w-2xl grid grid-row-2 lg:grid-cols-2 items-center justify-items-center bg-brand rounded-sm shadow-inner
             shadow-shadowBrand sm:mx-auto p-5"
            >
              <div className="w-5/6 overflow-hidden pSection">
                <div className=" aspect-w-4 aspect-h-4">
                  <Image
                    alt=""
                    publicId="p39hymv9ngwqufx9qwmp.png"
                    cloudName="group-2aes"
                    loading="lazy"
                    className="object-cover"
                    responsive
                    width="auto"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                    <Placeholder />
                  </Image>
                </div>
              </div>
              <div className="px-3 md:lg:px-4 lg:px-5 lg:py-3 2xl:px-3 pSection">
                <div className="overflow-hidden">
                  <h1 className="font-raleway font-normal text-whiteBrand text-2xl sm:text-3xl md:text-4xl pb-2">
                    Piattaforma di sharing{' '}
                    <span className="whitespace-nowrap"> e-commerce</span>
                  </h1>
                  <h2 className="font-Open font-bold  text-whiteBrand text-lg md:text-2xl ">
                    Per dare valore al territorio
                  </h2>
                  <h3 className="font-Open font-bold text-whiteBrand text-md md:text-xl ">
                    in arrivo....
                  </h3>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* contatti */}
        <Suspense fallback={<div>Caricamento in corso...</div>}>
          <ContactForm />
        </Suspense>
        {/* footer */}
        <Suspense fallback={<div>Caricamento in corso...</div>}>
          <Footer />
        </Suspense>
      </article>
    </div>
  )
}
