/* eslint-disable no-unused-vars */
export default function LogoNode({
  color = '#30524e',
  backColor = '#fff',
  ...props
}) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 442.31 270.92"
      {...props}
    >
      <defs>
        <linearGradient
          id="Sfumatura_senza_nome"
          x1="199.16"
          y1="-125.83"
          x2="161.1"
          y2="-48.17"
          gradientTransform="translate(0 221.04)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3f873f" />
          <stop offset="0.33" stopColor="#3f8b3d" />
          <stop offset="0.64" stopColor="#3e9637" />
          <stop offset="0.93" stopColor="#3da92e" />
          <stop offset="1" stopColor="#3dae2b" />
        </linearGradient>
        <clipPath id="clip-path" transform="translate(-18.8 -14.58)">
          <path
            d="M184.6,77.1a5.15,5.15,0,0,0-5.1,0l-42.1,24.3a5.31,5.31,0,0,0-2.6,4.4v48.7a5.09,5.09,0,0,0,2.6,4.4l42.1,24.3a5.15,5.15,0,0,0,5.1,0l42.1-24.3a5,5,0,0,0,2.5-4.4V105.8a5.21,5.21,0,0,0-2.5-4.4Z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="Sfumatura_senza_nome_2"
          x1="175.69"
          y1="-85.14"
          x2="282.49"
          y2="-164.05"
          gradientTransform="translate(0 221.04)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14" stopColor="#3f873f" />
          <stop offset="0.4" stopColor="#529f44" />
          <stop offset="0.71" stopColor="#63b649" />
          <stop offset="0.91" stopColor="#6abf4b" />
        </linearGradient>
        <linearGradient
          id="Sfumatura_senza_nome_3"
          x1="133.37"
          y1="-152.69"
          x2="230.79"
          y2="-152.69"
          gradientTransform="translate(-18.8 206.46)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#6abf4b" />
          <stop offset="0.29" stopColor="#63b649" />
          <stop offset="0.6" stopColor="#529f44" />
          <stop offset="0.86" stopColor="#3f873f" />
        </linearGradient>
        <linearGradient
          id="Sfumatura_senza_nome_4"
          x1="133.37"
          y1="-90.88"
          x2="230.79"
          y2="-90.88"
          gradientTransform="translate(0 221.04)"
          xlinkHref="#Sfumatura_senza_nome_3"
        />
        <linearGradient
          id="Sfumatura_senza_nome_5"
          x1="133.37"
          y1="-63.04"
          x2="230.79"
          y2="-63.04"
          xlinkHref="#Sfumatura_senza_nome_3"
        />
        <linearGradient
          id="Sfumatura_senza_nome_6"
          x1="133.37"
          y1="-50.59"
          x2="230.79"
          y2="-50.59"
          gradientTransform="translate(0 221.04)"
          xlinkHref="#Sfumatura_senza_nome_3"
        />
        <linearGradient
          id="Sfumatura_senza_nome_7"
          x1="244.89"
          y1="-128.15"
          x2="184.81"
          y2="-5.58"
          xlinkHref="#Sfumatura_senza_nome"
        />
      </defs>
      <path
        d="M237.5,285.5a8.77,8.77,0,0,1-4.2-1.1L220,276.5c-2-1.1-1-1.5-.4-1.7a32.38,32.38,0,0,0,6-2.7.92.92,0,0,1,1,.1l10.2,6.1a1.27,1.27,0,0,0,1.2,0l40-23.1a1.15,1.15,0,0,0,.6-1.1V208a1.15,1.15,0,0,0-.6-1.1l-39.9-23a1.27,1.27,0,0,0-1.2,0l-39.9,23a1.15,1.15,0,0,0-.6,1.1v46a1.25,1.25,0,0,0,.6,1.1l10.9,6.3c5.9,3,9.6-.5,9.6-4V211.8a1.2,1.2,0,0,1,1.1-1.2h5.1a1.2,1.2,0,0,1,1.2,1.1h0v45.5c0,7.9-4.3,12.5-11.8,12.5-2.3,0-4.1,0-9.2-2.5l-10.5-6a8.44,8.44,0,0,1-4.2-7.3V207.8a8.44,8.44,0,0,1,4.2-7.3l39.9-23.1a8.93,8.93,0,0,1,8.4,0l40,23.1a8.44,8.44,0,0,1,4.2,7.3V254a8.44,8.44,0,0,1-4.2,7.3l-40,23.1A8.77,8.77,0,0,1,237.5,285.5Z"
        transform="translate(-18.8 -14.58)"
        fill="#679e63"
      />
      <path
        d="M249.8,253.7c-17.5,0-21.1-8-21.1-14.8a1.11,1.11,0,0,1,1.1-1.1H235a1,1,0,0,1,1.1,1c.8,5.3,3.1,7.9,13.7,7.9,8.4,0,12-1.9,12-6.4,0-2.6-1-4.5-14.1-5.8-10.9-1.1-17.7-3.5-17.7-12.2,0-8.1,6.8-12.9,18.2-12.9,12.8,0,19.1,4.4,19.9,14a1.61,1.61,0,0,1-.3.9,1.27,1.27,0,0,1-.9.4h-5.2a1.17,1.17,0,0,1-1.1-.9c-1.2-5.5-4.3-7.3-12.5-7.3-9.2,0-10.3,3.2-10.3,5.6,0,2.9,1.3,3.8,13.7,5.4s18.1,3.9,18.1,12.5S262.5,253.7,249.8,253.7Z"
        transform="translate(-18.8 -14.58)"
        fill="#679e63"
      />
      <path
        d="M307.4,205.4a7.7,7.7,0,1,1-7.7-7.7A7.64,7.64,0,0,1,307.4,205.4Zm-14.2,0a6.45,6.45,0,1,0,6.5-6.4h0a6.4,6.4,0,0,0-6.5,6.4Zm3.6-4.3h3c1,0,3,0,3,2.3a2,2,0,0,1-1.6,2.1c1.2.1,1.3.9,1.4,2a6.35,6.35,0,0,0,.5,2.3h-1.8c0-.4-.3-2.6-.3-2.7-.1-.5-.3-.7-.9-.7h-1.5v3.5h-1.7Zm1.6,3.7h1.3a1.2,1.2,0,0,0,1.3-1v-.2c0-1.2-.8-1.2-1.3-1.2h-1.4v2.4Z"
        transform="translate(-18.8 -14.58)"
        fill="#679e63"
      />
      <path
        d="M113.7,105.1a5.09,5.09,0,0,0-2.6-4.4L68.8,76.3a6.15,6.15,0,0,0-2.3-.7h-.4a4.76,4.76,0,0,0-2.3.7L21.4,100.7a5.09,5.09,0,0,0-2.6,4.4l.1,65.6a2.48,2.48,0,0,0,1.3,2.2,2.27,2.27,0,0,0,2.5,0l25.2-14.4a5.09,5.09,0,0,0,2.6-4.4V123.4a5.09,5.09,0,0,1,2.6-4.4l10.7-6.2a5.94,5.94,0,0,1,2.6-.7,4.3,4.3,0,0,1,2.5.7L79.6,119a5.09,5.09,0,0,1,2.6,4.4v30.7a5.09,5.09,0,0,0,2.6,4.4L110,172.9a2.44,2.44,0,0,0,2.6,0,2.61,2.61,0,0,0,1.3-2.2Z"
        transform="translate(-18.8 -14.58)"
        fill="#333"
        fillRule="evenodd"
      />
      <path
        d="M317.5,14.9a2.62,2.62,0,0,0-3.5,1,2.66,2.66,0,0,0-.3,1.2v65a1.8,1.8,0,0,1-2.7,1.6l-10.6-6.1a5.15,5.15,0,0,0-5.1,0L253,102a5.09,5.09,0,0,0-2.6,4.4v48.9a5.09,5.09,0,0,0,2.6,4.4l42.4,24.5a5.15,5.15,0,0,0,5.1,0l42.4-24.5a5.09,5.09,0,0,0,2.6-4.4V33.4a5.13,5.13,0,0,0-2.6-4.5Zm-3.9,124.4a1.15,1.15,0,0,1-.6,1.1l-14.5,8.4a1.48,1.48,0,0,1-1.3,0l-14.5-8.4a1.25,1.25,0,0,1-.6-1.1V122.5a1.15,1.15,0,0,1,.6-1.1l14.5-8.4a1.48,1.48,0,0,1,1.3,0l14.5,8.4a1.15,1.15,0,0,1,.6,1.1Z"
        transform="translate(-18.8 -14.58)"
        fill="#333"
        fillRule="evenodd"
      />
      <path
        d="M458.6,122a4.8,4.8,0,0,0,2.5-4.4V105.8a5.21,5.21,0,0,0-2.5-4.4L416.5,77a5.15,5.15,0,0,0-5.1,0L369,101.3a5.09,5.09,0,0,0-2.6,4.4v48.9A5.09,5.09,0,0,0,369,159l42.1,24a5,5,0,0,0,5,0l25.5-14.1a2.62,2.62,0,0,0,1-3.5,3.18,3.18,0,0,0-1-1L399,140a2.61,2.61,0,0,1-1.3-2.2V122.5a2.48,2.48,0,0,1,1.3-2.2l13.3-7.6a2.27,2.27,0,0,1,2.5,0l13.3,7.6a2.61,2.61,0,0,1,1.3,2.2v12a2.56,2.56,0,0,0,2.5,2.6,2,2,0,0,0,1.3-.4Z"
        transform="translate(-18.8 -14.58)"
        fill="#333"
        fillRule="evenodd"
      />
      <path
        d="M413.3,119.7a.91.91,0,0,1,1,0l8.2,4.7a1.05,1.05,0,0,1,.5.9v9.4a1.05,1.05,0,0,1-.5.9l-8.1,4.7a.91.91,0,0,1-1,0l-8.1-4.7a1.05,1.05,0,0,1-.5-.9v-9.4a1.05,1.05,0,0,1,.5-.9Z"
        transform="translate(-18.8 -14.58)"
        fill="#679e63"
        fillRule="evenodd"
      />
      <path
        d="M184.6,77.1a5.15,5.15,0,0,0-5.1,0l-42.1,24.3a5.31,5.31,0,0,0-2.6,4.4v48.7a5.09,5.09,0,0,0,2.6,4.4l42.1,24.3a5.15,5.15,0,0,0,5.1,0l42.1-24.3a5,5,0,0,0,2.5-4.4V105.8a5.21,5.21,0,0,0-2.5-4.4Z"
        transform="translate(-18.8 -14.58)"
        fillRule="evenodd"
        fill="url(#Sfumatura_senza_nome)"
      />
      <g clipPath="url(#clip-path)">
        <path
          d="M179.4,77.1l-42.2,24.3a5.41,5.41,0,0,0-2.7,4.4v48.7a4.63,4.63,0,0,0,1.3,3.2l47.4-81.1A5.65,5.65,0,0,0,179.4,77.1Z"
          transform="translate(-18.8 -14.58)"
          fill="none"
        />
        <path
          d="M183.5,183.8a6.12,6.12,0,0,0,1.2-.5L226.9,159a5.09,5.09,0,0,0,2.6-4.4V105.9a5.09,5.09,0,0,0-1.5-3.5Z"
          transform="translate(-18.8 -14.58)"
          fill="none"
        />
        <path
          d="M226.8,101.4,184.6,77.1a6.94,6.94,0,0,0-1.3-.5l-47.4,81.2a5.26,5.26,0,0,0,1.4,1.2l42.3,24.3a4.88,4.88,0,0,0,3.9.5L228,102.4A10.59,10.59,0,0,0,226.8,101.4Z"
          transform="translate(-18.8 -14.58)"
          fill="url(#Sfumatura_senza_nome_2)"
        />
        <path
          d="M229.5,154.5V105.8a5.41,5.41,0,0,0-2.7-4.4L184.6,77.1a4.42,4.42,0,0,0-1.6-.6l46.3,79.1A3.59,3.59,0,0,0,229.5,154.5Z"
          transform="translate(-18.8 -14.58)"
          fill="none"
        />
        <path
          d="M137.2,101.4a5.41,5.41,0,0,0-2.7,4.4v48.7a5.41,5.41,0,0,0,2.7,4.4l42.3,24.3a4.54,4.54,0,0,0,3.2.6l-45.3-82.6Z"
          transform="translate(-18.8 -14.58)"
          fill="none"
        />
        <polygon
          points="159.3 53.62 158.8 53.92 159.5 53.92 159.3 53.62"
          fill="url(#Sfumatura_senza_nome_3)"
        />
        <path
          d="M226.9,158.9a5.69,5.69,0,0,0,2.5-3.2L183,76.5a5.66,5.66,0,0,0-3.6.6l-42,24.2,45.3,82.6a6,6,0,0,0,1.9-.6Z"
          transform="translate(-18.8 -14.58)"
          fill="url(#Sfumatura_senza_nome_4)"
        />
        <polygon
          points="212 143.52 211.7 143.12 211.7 143.72 212 143.52"
          fill="url(#Sfumatura_senza_nome_5)"
        />
        <path
          d="M226.9,158.9l-42.2,24.3a6,6,0,0,1-1.9.6l.8,1.5,46.8-27.1v-.6l-1.2-2A4.65,4.65,0,0,1,226.9,158.9Z"
          transform="translate(-18.8 -14.58)"
          fill="url(#Sfumatura_senza_nome_6)"
        />
        <path
          d="M226.9,158.9l-42.2,24.3a6,6,0,0,1-1.9.6l.8,1.5,46.8-27.1v-.6l-1.2-2A4.65,4.65,0,0,1,226.9,158.9Z"
          transform="translate(-18.8 -14.58)"
          fill="url(#Sfumatura_senza_nome_7)"
        />
      </g>
    </svg>
  )
}
