import { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
// import { Environment } from '@react-three/drei'
import Model4 from './components/Model4'
import Overlay4 from './components/Overlay4'

export default function Test4() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)

  return (
    // <div className="relative overflow-auto select-none h-full">
    <>
      <Canvas
        shadows
        onCreated={state => state.events.connect(overlay.current)}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY
          })
        }}
      >
        <ambientLight intensity={1} />
        {/* eslint-disable-next-line unicorn/no-null */}
        <Suspense fallback={null}>
          <Model4 scroll={scroll} />
          {/* <Environment preset="city" /> */}
        </Suspense>
      </Canvas>
      <Overlay4 ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}
