/* import preval from 'preval.macro' */
// import Link from 'next/link'
// import { useEffect } from 'react'
// import { utcToZonedTime } from 'date-fns-tz'
// import { getHours, getMinutes, getSeconds } from 'date-fns'
import InstagramIcon from './icons/Instagram'
import FacebookIcon from './icons/Facebook'
import LinkedinIcon from './icons/Linkedin'

// import Babbo from './lotties/babbo'

const Footer = () => (
  <div className="bg-branddark">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-4 text-white text-center pb-5 font-montserrat border-t-2">
      <div className="text-sm flex flex-col gap-3 pt-5">
        <h2 className="font-montserrat font-semibold text-xl pb-1">
          <a href="/">2AeS Group S.r.l.</a>
        </h2>
        <div className="text-sm flex font-montserrat flex-col gap-1 md:gap-2 pt-2">
          <div>P.IVA:12531390016 - REA: TO-1296976</div>
          <div>Corso Carlo e Nello Rosselli 119, Torino (10129, TO)</div>
          <div>Capitale Sociale: 10.002€ i.v.</div>
        </div>
      </div>

      <div className="text-sm flex font-montserrat flex-col gap-1 md:gap-2 pt-5">
        <h2 className="font-urbanist font-semibold text-xl">Informative</h2>
        <a href="/cookie-policy">Cookie policy</a>
        <a href="/privacy-policy">Privacy policy</a>
        <a href="/legali">Note Legali</a>
      </div>

      <div className="pt-5 items-center flex flex-col gap-3">
        <h2 className="font-urbanist font-semibold text-xl">Social</h2>
        <div className="font-bold text-lg items-center flex flex-row gap-3">
          <a
            target="_blank"
            href="https://instagram.com/2aesgroup?utm_medium=copy_link"
            rel="noopener noreferrer"
            aria-label="instagram "
          >
            <InstagramIcon
              className="h-9 w-9 z-50  focus:outline-none"
              fill="#f1f1f1"
              // stroke="#A78BFA"
              strokeWidth="1"
            />
          </a>

          <a
            target="_blank"
            href="https://www.facebook.com/2aesgroup"
            rel="noopener noreferrer"
            aria-label="facebook "
          >
            <FacebookIcon
              className="h-9 w-9 z-50  focus:outline-none"
              fill="#f1f1f1"
              // stroke="#A78BFA"
              strokeWidth="1"
            />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/2aesgroup"
            rel="noopener noreferrer"
            aria-label="linkedin "
          >
            <LinkedinIcon
              className="h-9 w-9 z-50  focus:outline-none"
              fill="#f1f1f1"
              strokeWidth="4"
            />
          </a>
        </div>
        <a href="mailto:amministrazione@2aes-group.it?subject=Vorrei%20lavorare%20con%20voi">
          Lavora con noi
        </a>
      </div>
    </div>
    {/* <Clock2 /> */}
    <div className="text-gray-50 text-xs text-center py-6">
      2AeS Group S.r.l. - Startup Innovativa - ©{new Date().getFullYear()}{' '}
      &nbsp;Tutti i diritti riservati&nbsp;- &nbsp;
      <a className="underline" href="/infolegal">
        Informazioni di cui all’art. 25, co. 11, D.L. 179/2012
      </a>{' '}
      {/*  <p className="text-xs  font-mono text-gray-900">{preval`module.exports = new Date().toISOString();`}</p> */}
    </div>
  </div>
)

export default Footer

// const getTimes = () => {
//   const now = new Date()
//   // Set the time manually for each of the clock types we're using
//   const times = [
//     // {
//     //   jsclass: 'js-tokyo',
//     //   jstime: moment.tz(now, 'Asia/Tokyo')
//     // },
//     {
//       jsclass: 'js-london',
//       jstime: utcToZonedTime(now, 'Europe/London')
//     }
//     // {
//     //   jsclass: 'js-new-york',
//     //   jstime: moment.tz(now, 'America/New_York')
//     // }
//   ]
//   return times
// }

// const initInternationalClocks = () => {
//   // Initialise the clock settings and the three times
//   const times = getTimes()

//   // eslint-disable-next-line no-restricted-syntax
//   for (const time of times) {
//     const hours = getHours(time)
//     const minutes = getMinutes(time)
//     const seconds = getSeconds(time)

//     const degrees = [
//       {
//         hand: 'hours',
//         degree: hours * 30 + minutes / 2
//       },
//       {
//         hand: 'minutes',
//         degree: minutes * 6
//       },
//       {
//         hand: 'seconds',
//         degree: seconds * 6
//       }
//     ]
//     // eslint-disable-next-line no-plusplus
//     for (let index = 0; index < degrees.length; index++) {
//       const elements = document.querySelectorAll(
//         // eslint-disable-next-line security/detect-object-injection
//         `.active .${time.jsclass} .${degrees[index].hand}`
//       )
//       // eslint-disable-next-line no-restricted-syntax
//       for (const element of elements) {
//         // eslint-disable-next-line security/detect-object-injection
//         element.style.webkitTransform = `rotateZ(${degrees[index].degree}deg)`
//         // eslint-disable-next-line security/detect-object-injection
//         element.style.transform = `rotateZ(${degrees[index].degree}deg)`
//         // If this is a minute hand, note the seconds position (to calculate minute position later)
//         // eslint-disable-next-line security/detect-object-injection
//         if (degrees[index].hand === 'minutes') {
//           element.parentNode.dataset.secondAngle = degrees[index + 1].degree
//         }
//       }
//     }
//   }

//   // Add a class to the clock container to show it
//   const elementsToActivate = document.querySelectorAll('.clock')
//   // eslint-disable-next-line no-restricted-syntax
//   for (const element of elementsToActivate) {
//     element.className = `${element.className} show`
//   }
// }

// function moveSecondHands() {
//   const containers = document.querySelectorAll('.bounce .seconds-container')
//   setInterval(() => {
//     // eslint-disable-next-line no-restricted-syntax
//     for (const container of containers) {
//       if (container.angle === undefined) {
//         container.angle = 6
//       } else {
//         container.angle += 6
//       }
//       container.style.webkitTransform = `rotateZ(${container.angle}deg)`
//       container.style.transform = `rotateZ(${container.angle}deg)`
//     }
//   }, 1000)
//   // eslint-disable-next-line no-restricted-syntax
//   for (const container of containers) {
//     // Add in a little delay to make them feel more natural
//     const randomOffset = Math.floor(Math.random() * (100 - 10 + 1)) + 10
//     container.style.transitionDelay = `0.0${randomOffset}s`
//   }
// }

// function moveMinuteHands(containers) {
//   // eslint-disable-next-line no-restricted-syntax
//   for (const container of containers) {
//     container.style.webkitTransform = 'rotateZ(6deg)'
//     container.style.transform = 'rotateZ(6deg)'
//   }
//   // Then continue with a 60 second interval
//   setInterval(() => {
//     // eslint-disable-next-line no-restricted-syntax
//     for (const container of containers) {
//       if (container.angle === undefined) {
//         container.angle = 12
//       } else {
//         container.angle += 6
//       }
//       container.style.webkitTransform = `rotateZ(${container.angle}deg)`
//       container.style.transform = `rotateZ(${container.angle}deg)`
//     }
//     // eslint-disable-next-line unicorn/numeric-separators-style
//   }, 60000)
// }

// function setUpMinuteHands() {
//   // More tricky, this needs to move the minute hand when the second hand hits zero
//   const containers = document.querySelectorAll('.minutes-container')
//   const { secondAngle } = containers[containers.length - 1].dataset

//   if (secondAngle > 0) {
//     // Set a timeout until the end of the current minute, to move the hand
//     const delay = ((360 - secondAngle) / 6 + 0.1) * 1000

//     setTimeout(() => {
//       moveMinuteHands(containers)
//     }, delay)
//   }
// }

// const Clock2 = () => {
//   useEffect(() => {
//     // Initialise the locale-enabled clocks
//     initInternationalClocks()
//     // Start the seconds container moving
//     moveSecondHands()
//     // Set the intial minute hand container transition, and then each subsequent step
//     setUpMinuteHands()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   return (
//     <div className="clock-container clocks active bounce">
//       {/* <article className="clock station js-new-york">
//       <section className="label">New York</section>
//       <section className="hours-container">
//         <section className="hours" />
//       </section>
//       <section className="minutes-container">
//         <section className="minutes" />
//       </section>
//       <section className="seconds-container">
//         <section className="seconds" />
//       </section>
//     </article> */}
//       <div className="clock station js-london">
//         <div className="label">London</div>
//         <div className="hours-container">
//           <div className="hours" />
//         </div>
//         <div className="minutes-container">
//           <div className="minutes" />
//         </div>
//         <div className="seconds-container">
//           <div className="seconds" />
//         </div>
//       </div>
//       {/* <article className="clock station js-tokyo">
//       <section className="label">Tokyo</section>
//       <section className="hours-container">
//         <section className="hours" />
//       </section>
//       <section className="minutes-container">
//         <section className="minutes" />
//       </section>
//       <section className="seconds-container">
//         <section className="seconds" />
//       </section>
//     </article> */}
//     </div>
//   )
// }

// // eslint-disable-next-line no-unused-vars
// const Clock = () => (
//   <div className="clock mx-auto my-4">
//     <div className="clock__second" />
//     <div className="clock__minute" />
//     <div className="clock__hour" />
//     <div className="clock__axis" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//     <div className="clock__indicator" />
//   </div>
// )
