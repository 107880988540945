/* eslint-disable react/jsx-filename-extension */
import { render } from 'react-dom'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import './styles.scss'
import 'react-toastify/dist/ReactToastify.css'
import { CookiesProvider } from 'react-cookie'
import { ToastContainer } from 'react-toastify'
import CookieConsent from 'react-cookie-consent'
import App from './App'

render(
  <React.StrictMode>
    <Helmet>
      {/* https://github.com/joshbuchea/HEAD */}
      <meta charset="utf8" />

      {/* <!-- Set the base URL for all relative URLs within the document --> */}
      <title>2AeS Group &ndash; Digitalizzazione Torino</title>

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      <meta
        name="keywords"
        content="digitalizzazione torino,e-commerce,torino,siti-web,digital,digitale,digitalizzazione,impresa,imprese,innovative,innovativa"
      />
      <link rel="canonical" href="https://www.2aes-group.it" />

      {/* <!-- Short description of the document (limit to 150 characters) --> */}
      {/* <!-- This content *may* be used as a part of search engine results. --> */}
      <meta
        name="description"
        content="Il tuo partner tecnologico. Siti web, E-commerce, branding, social"
      />

      {/* <!-- Control the behavior of search engine crawling and indexing --> */}
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />

      {/* <!-- For IE 10 and below --> */}
      {/* <!-- Place favicon.ico in the root directory - no tag necessary --> */}

      {/* <!-- Icon in the highest resolution we need it for --> */}
      <link rel="icon" sizes="192x192" href="/logo.png" />

      {/* <!-- Apple Touch Icon (reuse 192px icon.png) --> */}
      <link rel="apple-touch-icon" href="/logo.png" />

      {/* <!-- Safari Pinned Tab Icon --> */}
      <link rel="mask-icon" href="/logo.png" color="green" />

      {/* <meta property="fb:app_id" content="123456789" /> */}
      <meta property="og:url" content="https://www.2aes-group.it" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="2AeS Group" />
      <meta
        property="og:description"
        content="Il tuo partner tecnologico. Siti web, E-commerce, branding, social"
      />
      <meta property="og:image" content="https://www.2aes-group.it/logo.png" />
      <meta property="og:image:alt" content="2aes-group logo" />
      <meta property="og:site_name" content="2AeS Group" />
      <meta property="og:locale" content="it_IT" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Tempo di lettura stimato" />
      <meta name="twitter:data1" content="3 minuti" />
      {/* <meta property="article:author" content="" /> */}

      {/* <script
        defer
        data-domain="2aes-group.it"
        data-api="/aloha/api/event"
        src="/aloha/api/script.js"
      /> */}

      <script
        async
        defer
        data-website-id="2c900236-5145-4aac-b293-f27e2de7adc4"
        src="https://uma.2aesgroup.it/umami.js"
      />
    </Helmet>
    <ToastContainer />
    {/* https://github.com/Mastermindzh/react-cookie-consent#readme */}

    <CookieConsent
      location="bottom"
      buttonText="Accetta"
      cookieName="AcceptCookie"
      enableDeclineButton
      declineButtonText="Rifiuta"
      disableStyles
      onDecline={() => {
        // alert('fai qualcosa!!!')
      }}
      // style={{ background: '#2B373B' }}
      acceptOnOverlayClick
      acceptOnScroll
      /* buttonStyle={{
        fontSize: '16px',
        borderRadius: '0.5rem',
        backgroundColor: '#f1f1f1',
        margin: '10px',
        padding: '10px'
      }}
      declineButtonStyle={{
        fontSize: '16px',
        borderRadius: '0.5rem',
        backgroundColor: '#f1f1f1',
        margin: '10px',
        padding: '30px',
        verticalAlign: 'center'
      }} */
      containerClasses="cookie-container"
      contentClasses="cookie-content"
      buttonWrapperClasses="cookie-btn-wrapper"
      buttonClasses="cookie-btn-accept"
      declineButtonClasses="cookie-btn-decline"
      expires={365}
    >
      Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per
      finalità tecniche e, con il tuo consenso, anche per altre finalità come
      specificato nella{' '}
      <span className="underline">
        <a href="/cookie-policy">cookie policy.</a>
      </span>{' '}
      Puoi acconsentire all’utilizzo di tali tecnologie utilizzando il pulsante
      “Accetta”. Chiudendo questa informativa, continui senza accettare.
    </CookieConsent>
    <CookiesProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>,
  document.querySelector('#root')
)
