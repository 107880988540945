/* eslint-disable no-unused-vars */
export default function Logo({
  color = '#30524e',
  backColor = '#fff',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 136.06 136.06"
      /* fill={color} */
      {...props}
    >
      <rect width="136.06" height="136.06" fill="#14403b" />
      <path
        d="M58.41,103.2a10.12,10.12,0,0,1-2.32-.25,4.24,4.24,0,0,1-1.75-.8,1.74,1.74,0,0,1-.67-1.38,1.84,1.84,0,0,1,.65-1.4,4.52,4.52,0,0,1,1.77-.88l.14.38a1.44,1.44,0,0,0-.82.64,2.05,2.05,0,0,0-.3,1.09,1.8,1.8,0,0,0,1,1.64,5.06,5.06,0,0,0,2.56.57,7.72,7.72,0,0,0,2-.27,3.81,3.81,0,0,0,1.65-.89A2.21,2.21,0,0,0,63,100a1.64,1.64,0,0,0-.55-1.26,3.3,3.3,0,0,0-2.14-.5H58.59a8.57,8.57,0,0,1-1.44-.12A2.29,2.29,0,0,1,56,97.64a1.39,1.39,0,0,1-.44-1.14A2.24,2.24,0,0,1,56.23,95a7,7,0,0,1,2.38-1.49l.28.2a11.24,11.24,0,0,0-1.37.85,1.26,1.26,0,0,0-.58,1c0,.49.38.74,1.12.74H61a5.42,5.42,0,0,1,1.79.28,2.92,2.92,0,0,1,1.34.88A2.36,2.36,0,0,1,64.6,99a3.45,3.45,0,0,1-.7,2,4.82,4.82,0,0,1-2.07,1.54A8.78,8.78,0,0,1,58.41,103.2Zm.11-9.3a5.25,5.25,0,0,1-2.06-.38A3.12,3.12,0,0,1,55,92.33a4.31,4.31,0,0,1,0-4.18A3.06,3.06,0,0,1,56.46,87a5.83,5.83,0,0,1,4.14,0,3.14,3.14,0,0,1,1.45,1.19,4.39,4.39,0,0,1,0,4.18,3.2,3.2,0,0,1-1.45,1.19A5.28,5.28,0,0,1,58.52,93.9Zm0-.41a1.61,1.61,0,0,0,1.38-.7,6.75,6.75,0,0,0,0-5.1,1.59,1.59,0,0,0-1.38-.7,1.61,1.61,0,0,0-1.37.7,6.63,6.63,0,0,0,0,5.1A1.63,1.63,0,0,0,58.52,93.49Zm3.34-5-.42-.16A3.34,3.34,0,0,1,62.56,87a2.61,2.61,0,0,1,1.64-.61,1.44,1.44,0,0,1,1,.34,1.31,1.31,0,0,1,.37,1,1.19,1.19,0,0,1-.39,1,1.28,1.28,0,0,1-.77.29,1,1,0,0,1-.65-.25,1.06,1.06,0,0,1-.35-.77,2.92,2.92,0,0,1,.33-1.37l.3.07a4,4,0,0,0-1.39.78A4.2,4.2,0,0,0,61.86,88.5Z"
        fill="#fff"
      />
      <path
        d="M74.78,86.58a2.32,2.32,0,0,1,1.1.25,2.06,2.06,0,0,1,.74.66,1.63,1.63,0,0,1,.27.92,1.59,1.59,0,0,1-.36,1,1.19,1.19,0,0,1-1,.44,1.27,1.27,0,0,1-.85-.32,1.07,1.07,0,0,1-.37-.89,1.2,1.2,0,0,1,.24-.75,2,2,0,0,1,.54-.5.55.55,0,0,0-.48-.23,2.24,2.24,0,0,0-1.33.43,3.72,3.72,0,0,0-1,1.1,6.25,6.25,0,0,0-.64,1.42,4.74,4.74,0,0,0-.24,1.41v4.91A1.6,1.6,0,0,0,72.07,98a3.57,3.57,0,0,0,1.8.38v.49l-1.51-.06c-.64,0-1.33,0-2.06,0q-.8,0-1.56,0l-1.18.06v-.49A2.1,2.1,0,0,0,68.92,98a1.71,1.71,0,0,0,.41-1.32V89.43A2.62,2.62,0,0,0,69,87.89a1.64,1.64,0,0,0-1.4-.5V86.9c.5,0,1,.07,1.44.07a10.64,10.64,0,0,0,1.29-.08,6.86,6.86,0,0,0,1.12-.25v2.9a6.64,6.64,0,0,1,.73-1.35A4.49,4.49,0,0,1,73.29,87,2.55,2.55,0,0,1,74.78,86.58Z"
        fill="#fff"
      />
      <path
        d="M84.28,86.58a5.49,5.49,0,0,1,2.67.64,4.67,4.67,0,0,1,1.89,2,9.53,9.53,0,0,1,0,7.22,4.67,4.67,0,0,1-1.89,2,5.77,5.77,0,0,1-5.32,0,4.63,4.63,0,0,1-1.9-2,9.53,9.53,0,0,1,0-7.22,4.63,4.63,0,0,1,1.9-2A5.47,5.47,0,0,1,84.28,86.58Zm0,.46a2.49,2.49,0,0,0-2.16,1.37,8.78,8.78,0,0,0-.85,4.47,8.78,8.78,0,0,0,.85,4.47,2.41,2.41,0,0,0,4.33,0,8.78,8.78,0,0,0,.85-4.47,8.78,8.78,0,0,0-.85-4.47A2.5,2.5,0,0,0,84.28,87Z"
        fill="#fff"
      />
      <path
        d="M103,86.64v9.7a2.57,2.57,0,0,0,.38,1.54,1.67,1.67,0,0,0,1.38.5v.49a13.88,13.88,0,0,0-1.44-.07,10.52,10.52,0,0,0-1.28.07,4.8,4.8,0,0,0-1.13.25V96.38a4.15,4.15,0,0,1-1.63,2.15,4.22,4.22,0,0,1-2.17.64,3.88,3.88,0,0,1-1.42-.24,2.65,2.65,0,0,1-1-.64,2.48,2.48,0,0,1-.61-1.21A9.42,9.42,0,0,1,94,95.25V89.43a2.55,2.55,0,0,0-.37-1.54,1.62,1.62,0,0,0-1.39-.5V86.9a14.16,14.16,0,0,0,1.44.07,10.47,10.47,0,0,0,1.28-.08A7,7,0,0,0,96,86.64v9.12a8.93,8.93,0,0,0,.09,1.32,1.65,1.65,0,0,0,.47,1,1.61,1.61,0,0,0,1.2.37,2.53,2.53,0,0,0,1.61-.55,3.72,3.72,0,0,0,1.12-1.51,5.36,5.36,0,0,0,.4-2.13v-4.8a2.55,2.55,0,0,0-.37-1.54,1.62,1.62,0,0,0-1.39-.5V86.9a14.16,14.16,0,0,0,1.44.07,10.47,10.47,0,0,0,1.28-.08A7,7,0,0,0,103,86.64Z"
        fill="#fff"
      />
      <path
        d="M110.83,86.64v14.24a1.42,1.42,0,0,0,.59,1.34,3.4,3.4,0,0,0,1.64.33V103l-1.42-.06q-.9,0-1.92,0c-.48,0-1,0-1.43,0l-1.07.06v-.49a1.82,1.82,0,0,0,1.18-.3,1.48,1.48,0,0,0,.35-1.14V89.43a2.54,2.54,0,0,0-.38-1.54,1.61,1.61,0,0,0-1.39-.5V86.9c.5,0,1,.07,1.44.07a10.64,10.64,0,0,0,1.29-.08A6.86,6.86,0,0,0,110.83,86.64Zm3.71-.06a3.86,3.86,0,0,1,2.21.66,4.5,4.5,0,0,1,1.54,1.93,7.73,7.73,0,0,1,.57,3.16,9.89,9.89,0,0,1-.31,2.47,7.33,7.33,0,0,1-.93,2.2A5,5,0,0,1,116,98.58a4.74,4.74,0,0,1-2.42.59,3.42,3.42,0,0,1-1.89-.51,2.52,2.52,0,0,1-1.08-1.23l.23-.33a2.9,2.9,0,0,0,.94,1.09,2.53,2.53,0,0,0,1.54.44,2.56,2.56,0,0,0,2-.81,4.71,4.71,0,0,0,1-2.17,14.54,14.54,0,0,0,.29-3,12.69,12.69,0,0,0-.3-3,3.74,3.74,0,0,0-.91-1.76,2,2,0,0,0-1.48-.58,2.84,2.84,0,0,0-2.07.94,4.85,4.85,0,0,0-1.18,2.84l-.28-.32a4.94,4.94,0,0,1,1.41-3.07A3.85,3.85,0,0,1,114.54,86.58Z"
        fill="#fff"
      />
      <path
        d="M37.8,48.23a9.68,9.68,0,0,1-.69,3.54,22.13,22.13,0,0,1-1.79,3.53,38.57,38.57,0,0,1-2.5,3.51c-.94,1.16-1.89,2.3-2.87,3.43L27.1,65.5c-.91,1-1.73,2-2.45,3l-.74.84L25,69.18H38.34V74H16.41v-2.5a27.53,27.53,0,0,1,2.55-4c1-1.34,2.08-2.68,3.19-4s2.22-2.66,3.33-4,2.11-2.59,3-3.83A24.19,24.19,0,0,0,30.63,52a7.65,7.65,0,0,0,.84-3.32,5.07,5.07,0,0,0-.47-2.2,4.91,4.91,0,0,0-1.25-1.65,5.36,5.36,0,0,0-1.86-1,7.55,7.55,0,0,0-2.31-.35,13.1,13.1,0,0,0-2.79.35,16,16,0,0,0-3.49,1.27l-1.57-4.07a18,18,0,0,1,4.47-1.59,20.08,20.08,0,0,1,3.92-.42,17.43,17.43,0,0,1,4.73.61,11.08,11.08,0,0,1,3.68,1.79,8.33,8.33,0,0,1,2.41,2.9A8.57,8.57,0,0,1,37.8,48.23Z"
        fill="#fff"
      />
      <path
        d="M56.24,38.33h6L75.08,74H68.65L59.43,46.62,59,45l-.4,1.71L54,60.55l-.59,1,.69-.1h4.81a3.56,3.56,0,0,1,1.74.42,4.45,4.45,0,0,1,1.3,1,6.91,6.91,0,0,1,.93,1.45,12.67,12.67,0,0,1,.64,1.54H52.22L49.52,74H43.44Z"
        fill="#fff"
      />
      <path
        d="M88.56,65.3a4,4,0,0,0-.11-1.48,3.69,3.69,0,0,0-.59-1.21,2.9,2.9,0,0,0-1-.81,2.79,2.79,0,0,0-1.24-.29,3.41,3.41,0,0,0-2.81,1.38,6.2,6.2,0,0,0-1.08,3.92A7.1,7.1,0,0,0,82.09,69,5.3,5.3,0,0,0,83,70.77a4.11,4.11,0,0,0,1.38,1.12,3.76,3.76,0,0,0,1.73.4,8.4,8.4,0,0,0,1.79-.19,9.38,9.38,0,0,0,2-.8l.68,1.81a9.16,9.16,0,0,1-2.55.92,12.49,12.49,0,0,1-2.22.23,6.64,6.64,0,0,1-2.74-.56A6,6,0,0,1,81,72.14a7,7,0,0,1-1.33-2.37,9.28,9.28,0,0,1-.47-3,9,9,0,0,1,.47-3A6.46,6.46,0,0,1,81,61.5a5.9,5.9,0,0,1,2.11-1.45,7.28,7.28,0,0,1,2.8-.51,4.9,4.9,0,0,1,4.25,2.32,5.78,5.78,0,0,1,.8,2.42,7.22,7.22,0,0,1-.28,2.85h-8a3.61,3.61,0,0,1,.82-1.31,2,2,0,0,1,1.51-.52Z"
        fill="#fff"
      />
      <path
        d="M107.19,42.15a5.41,5.41,0,0,0-3.8,1.25A4.06,4.06,0,0,0,102,46.51a4.22,4.22,0,0,0,1.23,3,14.44,14.44,0,0,0,3.09,2.37q1.86,1.11,4,2.23a26.71,26.71,0,0,1,4,2.57,12.76,12.76,0,0,1,3.09,3.43,8.9,8.9,0,0,1,1.23,4.78,9,9,0,0,1-.86,3.94,8.78,8.78,0,0,1-2.43,3.06,11.32,11.32,0,0,1-3.82,2,16.61,16.61,0,0,1-5,.71,25.54,25.54,0,0,1-4.91-.55,26.11,26.11,0,0,1-5.84-2l1.62-4a23.54,23.54,0,0,0,4.78,1.6,20.72,20.72,0,0,0,4.1.46,8.61,8.61,0,0,0,2.67-.39A6.08,6.08,0,0,0,111,68.66,4.94,4.94,0,0,0,112.37,67a4.5,4.5,0,0,0,.46-2,4.65,4.65,0,0,0-1.22-3.24,14.07,14.07,0,0,0-3.07-2.43c-1.22-.74-2.56-1.46-4-2.16a24.25,24.25,0,0,1-4-2.46,13,13,0,0,1-3.07-3.27,8.1,8.1,0,0,1-1.23-4.57,9.14,9.14,0,0,1,.81-3.93,8.12,8.12,0,0,1,2.24-2.87,10.11,10.11,0,0,1,3.33-1.77,13.42,13.42,0,0,1,4.12-.61c.72,0,1.43,0,2.14.07s1.43.13,2.18.25a23.11,23.11,0,0,1,2.35.5c.82.21,1.7.47,2.65.76l-1.62,4a27.37,27.37,0,0,0-4-.88A23.05,23.05,0,0,0,107.19,42.15Z"
        fill="#fff"
      />
    </svg>
  )
}
