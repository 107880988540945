import { Transformation, Image, Placeholder } from 'cloudinary-react'

export default function Chisiamo() {
  return (
    <section className="relative sm:px-6 bg-whiteBrand py-4">
      <div className="text-center pb-12">
        <h1 className="font-raleway font-normal text-4xl sm:text-5xl md:text-6xl font-heading text-brand pt-5">
          I fondatori 2AeS
        </h1>
        <p className="pt-7 text-lg px-4 md:px-56 text-brand">
          Siamo una giovane e dinamica Startup innovativa. Come Team nasciamo,
          in Torino, ad Ottobre 2019. Successivamente ci siamo costituiti come
          2AeS Group Srl a Luglio 2020. Tre laureati del Politecnico di Torino
          (Ingegneria e Architettura) con delle passioni comune: la cultura
          enogastronomica del nostro bellissimo territorio e la tecnologia. Ecco
          chi è la 2AeS Group
        </p>
      </div>

      {/*  PERSONE */}
      <div className="max-w-5xl mx-auto">
        <div className="grid md:grid-cols-2 gap-6 lg:gap-3 items-center align-middle justify-items-center px-2 md:px-0">
          {/* card persona */}
          <Card
            img="Simo_resized_mnyncp"
            name="Simone Trinchero"
            info="Co-Founder"
            descr="L.M. Ing. Elettronica, abilitato all'esercizio della professione. Web designer, System Design e Operations. CEO"
          />
          <Card
            img="Ale_-_resized_mlv0sq"
            name="Alessio Zamparelli"
            info="Co-Founder"
            descr="L. Ing. Informatica, Web designer, programmatore. IT Manager e DataBase. CTO"
          />
          <Card
            img="Ang_-_resized_ezzr3y"
            name="Angelo Juliano Donato"
            info="Co-Founder"
            descr="L. Architettura, Grafic designer. Marketing e Comunicazione"
          />
        </div>
        <div className="text-center pb-12">
          <h1 className="font-raleway font-normal text-4xl sm:text-5xl md:text-6xl font-heading text-brand pt-10">
            I collaboratori
          </h1>
        </div>
        <div className="grid md:grid-cols-2 gap-6 lg:gap-3 items-center align-middle justify-items-center px-2 md:px-0">
          {/* card collaboratori */}
          <Card
            img="carta_l5snht"
            name="Elena Carta"
            info="Social Media Strategist"
            descr="L. Beni Culturali, Progettazione grafica e scrittura di articoli"
          />
        </div>
      </div>
    </section>
  )
}

const Card = ({ img, name, info, descr }) => (
  <div className="bg-branddark rounded-lg shadow-md shadow-shadowBrand overflow-hidden flex xs:flex-row xs:h-64 w-full">
    <div className="relative w-full flex-1">
      <div className="aspect-h-4 aspect-w-3 xs:p-0 xs:h-full overflow-hidden w-full">
        <Image
          alt={`foto ${name}`}
          publicId={img}
          className="object-top object-cover"
          cloudName="group-2aes"
          loading="lazy"
          responsive
          width="auto"
        >
          <Transformation quality="auto" fetchFormat="auto" />
          <Placeholder />
        </Image>
      </div>
    </div>

    <div className="md:w-4/5 p-2 md:p-2 space-y-2 text-center flex-1 align-middle mt-2 md:mt-14">
      <p className="text-lg font-Open text-whiteBrand font-bold">{name}</p>
      <p className="text-sm text-whiteBrand font-normal">{info}</p>
      <p className="text-base leading-normal text-whiteBrand font-normal">
        {descr}
      </p>
    </div>
  </div>
)
