import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'
import Logo from './components/Logo'

export default function Infolegge() {
  return (
    <>
      <div className="w-full mt-4">
        <Link to="/">
          <Logo className="h-24 w-24 mx-auto" />
        </Link>
      </div>
      <div className="pt-16 px-6 lg:px-20 xl:px-56 leading-7 text-justify pb-10">
        <div className="border-b-2 border-branddark">
          <h1 className="text-left md:text-4xl tracking-widest uppercase pb-3 text-lg ">
            Informazioni di cui all’art. 25, co. 11, D.L. 179/2012
          </h1>
        </div>

        <p className="font-bold">Costituzione</p>
        <div>
          <p>
            La Società 2AeS Group S.r.l. è stata costituita in data 06/07/2021
            presso lo studio notarile Naddeo Gabriele, Via Roma 2, Caselle
            Torinese (TO), 10072.&nbsp;
          </p>
          <p>Iscritta all’albo delle Startup Innovative</p>
          <p>
            PIVA/CF: 12531390016 – REA:TO-1296976 – PEC:
            amministrazione@pec.2aes-group.it
          </p>

          <p className="font-bold">Sede Legale</p>

          <p>
            Sede legale ed amministrativa in Corso Carlo e Nello Rosselli 119,
            Torino (TO), 10129. Nessun’altra sede operativa.
          </p>

          <p className="font-bold">Oggetto sociale:</p>

          <p>
            La società ha per oggetto lo sviluppo, la produzione e la
            commercializzazione di prodotti o servizi innovativi ad alto valore
            tecnologico, e più specificatamente, in via prevalente lo sviluppo,
            la produzione e la commercializzazione di innovative piattaforme
            software integrate, fruibili sia dal web che da applicazioni mobile,
            di servizi di marketplace condivisi, e-commerce e gestione integrata
            e ottimizzata del processo di vendita, finalizzati a supportare la
            digitalizzazione di esercizi commerciali e pmi in quanto funzionali
            alla gestione automatizzata di ordinazioni e richieste di consegna a
            domicilio, nonchè della comunicazione a distanza con cliente e
            terzi.I servizi sono innovativi perchè si basano su database,
            algoritmi e codici sorgenti di portali web ed e-commerce proprietari
            che sono sviluppati e gestiti internamente, e sono inoltre ad alto
            valore tecnologico in quanto realizzati attraverso l’integrazione di
            tecnologie hardware, piattaforme informatiche e applicazioni
            software che si avvalgono di strumenti di geolocalizzazione.
          </p>

          <p>La società potrà svolgere le seguenti attività secondarie:</p>
          <ul className="list-inside list-disc">
            <li>
              fornire consulenza e assistenza alle aziende, ai liberi
              professionisti e ai privati per lo sviluppo e l’implementazione
              delle nuove tecnologie nello svolgimento delle proprie attività;
            </li>
            <li>
              progettazione e sviluppo di applicazioni e sistemi elettronici
              tramite tecnologie abilitanti come pcb, hardware, software e
              firmware in ambito “industria 4.0”, domotica, “internet of
              things”, reti di sensori wireless e smart agricolture;
            </li>
            <li>
              concedere a terzi in licenza degli algoritmi sviluppati, o di
              parte di essi;
            </li>
            <li>
              ricercare, selezionare e fornire personale qualificato per conto
              terzi al fine di svolgere la funzione di corriere e trasportatore
              merci e/o erogazione di servizi;
            </li>
            <li>
              fornire servizi e consulenze per hosting specializzato quali
              hosting di siti web, servizi streaming e strutture mainframe
              basate su time-sharing;
            </li>
            <li>
              sviluppare servizi basati sulle logiche della “sharing economy”,
              anche avvalendosi di dispositivi hardware e software;
            </li>
            <li>
              sviluppare, produrre e commercializzare big data platform, ovvero
              piattaforme per l’analisi aggregata di dati;
            </li>
            <li>
              prestare servizi di progettazione, creazione, gestione e
              manutenzione di basi dati, app mobili, siti web, e adattamento di
              programmi informatici nei limiti e nel rispetto dei vincoli di
              legge;
            </li>
            <li>
              fornire consulenza a terzi e condurre campagne di marketing e
              pubblicitarie anche non convenzionali, sia fisiche che sul web,
              nelle diverse forme e modalità consentite dalla legge;
            </li>
            <li>
              fornire consulenza e servizi per la creazione di contenuti grafici
              e fotografici come loghi, dépliant, fotografie, video
              promozionali;
            </li>
            <li>
              sviluppare, produrre e commercializzare e condurre soluzioni seo e
              sem per lo sviluppo, il posizionamento e la promozione di siti web
              ed applicazioni mobili;
            </li>
            <li>
              organizzare, gestire ed erogare corsi, convegni, riunioni,
              congressi e fiere in proprio o per conto terzi, inclusa o meno la
              gestione e la fornitura di personale operativo nell’ambito delle
              strutture in cui hanno luogo gli eventi;
            </li>
            <li>
              sviluppare, produrre e commercializzare software che utilizzino
              algoritmi di intelligenza artificiale;
            </li>
            <li>
              commercializzare, contestualmente o meno alla fornitura di
              servizi, nei confronti di privati, società, enti pubblici in
              genere prodotti software, hardware e apparecchi informatici ed
              attrezzature it in genere;
            </li>
            <li>
              fornire servizi di consulenza, sviluppo, gestione, installazione,
              manutenzione e assistenza, in sede o presso terzi, di software,
              sistemi informatici in genere, sistemi hardware, elaboratori
              elettroni¬ci ed ogni altra apparecchiatura similare;
            </li>
            <li>
              fornire servizi accessori a domicilio a richiesta dell’utente
              finale;
            </li>
            <li>
              fornire servizi di ottimizzazione dei percorsi per i mezzi in
              circolazione anche mediante l’impiego di tecnologia satellitare.
              La società può assumere e concedere agenzie, commissioni,
              rappresentanze e mandati, nonché compiere tutte le operazioni
              commerciali, finanziarie, mobiliari ed immobiliari, necessarie o
              utili per il raggiungimento degli scopi sociali. La società può
              altresì assumere interessenze e partecipazioni in altre società o
              imprese di qualunque natura aventi oggetto analogo, affine o
              connesso al proprio. Potrà inoltre rilasciare fideiussioni e altre
              garanzie in genere, anche reali, purché direttamente connesse con
              l’oggetto sociale. Tutte tali attività potranno essere svolte in
              via non esclusiva o prevalente, non nei confronti del pubblico e
              nel rispetto delle vigenti norme in materia di attività riservate.
            </li>
          </ul>

          <p className="font-bold">Attività innovative e spese R&amp;D</p>

          <p>
            Come requisito per startup innovativa si è scelto il requisito spese
            ricerca e sviluppo di almeno il 15% del maggior valore tra costi e
            ricavi.
          </p>

          <p>
            L’attivita’ specifica cui fanno capo i requisiti relativi
            all’innovazione Tecnologica consiste nello sviluppo di database,
            algoritmi, applicazioni Software, web o mobili e grafiche per una
            piattaforma informatica di Marketplace condiviso. Si stimano spese
            in ricerca e sviluppo superiori al 15% dei costi, per il primo
            esercizio.
          </p>

          <p className="font-bold">Elenco dei soci e titoli di studio</p>

          <ul className="list-inside list-disc">
            <li>
              Donato (cognome) Angelo Juliano (nome), Laurea Triennale in
              scienze Dell’architettura presso Politecnico di Torino;
            </li>
            <li>
              Trinchero Simone, &nbsp;Laurea Magistrale in Ingegneria
              Elettronica presso Politecnico di Torino – Abilitazione
              All’esercizio della professione di ingegnere dell’informazione
              (non iscritto All’ordine). Precedente esperienza di lavoro:
              Hardware Development Engineer &nbsp;presso “flex milano design
              center”;
            </li>
            <li>
              Zamparelli Alessio, Laurea triennale in ingegneria informatica
              presso Politecnico di Torino. Precedente esperienza di lavoro:
              database &amp; Software development presso “i&amp;se srl”.
            </li>
          </ul>

          <p className="font-bold">Altre informazioni</p>

          <p>Non esistono al momento societa’ partecipate.</p>

          <p>
            Non esistono al momento relazioni professionali con incubatori
            certificati.
          </p>

          <p>Nessun diritto di privativa.</p>

          <p>Bilancio in fase di deposito</p>
        </div>
      </div>
      {/* footer */}
      <Suspense fallback={<div>Caricamento in corso...</div>}>
        <Footer />
      </Suspense>
    </>
  )
}
