import { forwardRef } from 'react'
import {
  ArrowCircleDownIcon
  // GlobeAltIcon,
  // LightningBoltIcon,
  // ScaleIcon
} from '@heroicons/react/outline'
// eslint-disable-next-line react/display-name
const Overlay4 = forwardRef(({ caption, scroll }, reference) => (
  <div
    ref={reference}
    onScroll={event => {
      // console.log(e)
      // eslint-disable-next-line no-param-reassign
      scroll.current =
        event.target.scrollTop /
        (event.target.scrollHeight - window.innerHeight)
      // eslint-disable-next-line no-param-reassign, unicorn/prefer-dom-node-text-content
      caption.current.innerText = scroll.current.toFixed(2)
    }}
    className="scroll"
  >
    <div style={{ height: '400vh' }} className="text-gray-900">
      <div className="dot ">
        <ArrowCircleDownIcon className="h-14" />
        <h1>SCORRI LA PAGINA</h1>
        La realtà virtuale può spingere al massimo l&apos;esperienza di un
        utente sul tuo sito web
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>Modelli 3D</h1>
        Ogni oggetto o prodotto può essere ricostruito con un modello 3D e
        inserito nel sito per mgliorare l&apos;esperienza utente
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>Framework</h1>Tutti i nostri siti web possono integrare, a
        richiesta, questa tipologia di animazioni.
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>2AeS</h1>Vetrine digitali, e-commerce innovativi, branding,
        digitalizzazione e siti web. Semplicità e tecnologia sono la nostra
        filosofia. Territorio e sostenibilità i nostri valori.
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>Territorio</h1>Realizziamo servizi innovativi capaci di supportare i
        negozi e le piccole e medie imprese commerciali ed alimentari del
        territorio.
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>Servizi</h1>La nostra forza è studiare, comprendere e ottimizzare le
        esigenze del cliente per trasferirle all’interno delle piattaforme da
        noi realizzate.
      </div>
    </div>
    <div style={{ height: '200vh' }}>
      <div className="dot">
        <ArrowCircleDownIcon className="h-14" />
        <h1>Provalo</h1>Torna alla home cliccando sotto. Tutti gli effetti qui
        mostrati e tanti altri possono essere integrati nel tuo sito web!
        Contattaci subito <br />
      </div>
    </div>
    <span className="caption" ref={caption}>
      0.00
    </span>
    <div>
      <span className="text-xl underline underline-offset-2 pb-4 pl-4">
        <a href="/#laptop">TORNA ALLA HOME 2AES</a>
      </span>
    </div>
  </div>
))

export default Overlay4
